









































































.fd-preloader-view {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: black;
	color: white;
	font-size: 34px;

	&.finished {
		opacity: 0;
		pointer-events: none;
	}

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -17px;
		margin-left: -17px;
	}

	img {
		position: absolute;
		right: -20px;
		width: 1px;
		height: 1px;
	}
}
