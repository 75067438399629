

























































.fd-iframe-module {
	background: rgba(255, 255, 255, 0.8);

	position: relative;
	overflow: hidden;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		z-index: 1;
		pointer-events: none;
	}
}
