

























































































































































































































































































































































































































.fd-meter-module-default {
	.tacho-bg, .tacho-fg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.tacho-fg {
		height: 200%;
		pointer-events: none;
		z-index: 15;
	}

	&.hide-skala .meter-scala {
		display: none;
	}

	.meter-scala {
		position: absolute;
		z-index: 10;
		font-family: "OpenSans", Arial, sans-serif;
	}

	.meter-scala-0 {
		bottom: 0;
		left: 0;
		padding-bottom: 3%;
		width: 25%;
		text-align: center;
	}

	.meter-scala-1 {
		top: 0;
		left: 50%;
		width: 26%;
		margin-left: -13%;
		text-align: center;
		padding: 10% 0;
	}

	.meter-scala-2 {
		bottom: 0;
		right: 0;
		padding-bottom: 3%;
		width: 25%;
		text-align: center;
	}
}

.fd-meter-module-ov {
	background: url('../../img/tacho/tacho.png') no-repeat center center;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.tacho-fg {
		width: 100%;
		height: 200%;
		pointer-events: none;
		position: absolute;
		z-index: 15 !important;
	}

	&.hide-skala .meter-scala {
		display: none;
	}

	.meter-scala {
		position: absolute;
		z-index: 10;
		font-family: "OpenSans", Arial, sans-serif;
	}

	.meter-scala-0 {
		bottom: 0;
		left: 25%;
		width: 25%;
		padding: 14% 0;
		text-align: center;
	}

	.meter-scala-1 {
		top: 0;
		left: 26%;
		width: 25%;
		padding: 15% 0;
		text-align: center;
	}

	.meter-scala-2 {
		top: 0;
		left: 50%;
		width: 26%;
		margin-left: -13%;
		text-align: center;
		padding: 8% 0;
	}

	.meter-scala-3 {
		top: 0;
		left: 0;
		width: 123%;
		padding: 15% 0;
		text-align: center;
	}

	.meter-scala-4 {
		bottom: 0;
		right: 0;
		padding: 14% 0;
		width: 79%;
		text-align: center;
	}
	.meter-kw {
		position: absolute;
		font-family: "OpenSans", Arial, sans-serif;
		font-weight: inherit;
		padding: 0% 44%;
		padding-top: 29%;
	}

	.meter-kwh {
		position: absolute;
		font-family: "OpenSans", Arial, sans-serif;
		font-weight: inherit;
		padding: 0% 42%;
		padding-top: 29%;
	}

	.meter-percent {
		position: absolute;
		font-family: "OpenSans", Arial, sans-serif;
		font-weight: inherit;
		padding: 0% 46%;
		padding-top: 29%;
	}
}
