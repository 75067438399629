







































































































































.fd-weather-module {
	display: flex !important;
	align-items: center;
	justify-content: center;

	.icon {
		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}
	.text {
		width: 100%;
	}
}
