



























.fd-config-item.Preview {
	flex-direction: column;

	& > * {
		width: 100% !important;

		&.label {
			margin-bottom: 3px;
		}
	}
}
.fd-config-item.Preview .fd-preview {
	width: 100%;

	.preview {
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;

		&:before {
			content: '';
			display: block;
			padding-bottom: 56.25%;
		}

		position: relative;

		.info {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;

			color: #fff;
			background: hsla(0,0%,0%,.8);
			padding: 5px;
			font-size: 15px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: stretch;

			& > * {
				text-align: center;
			}

			.name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			transition: all .2s;
			opacity: 0;
		}

		&:hover .info {
			opacity: 1;
		}

		.badge {
			position: absolute;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, .4);

			padding: 4px 8px 5px 1px;

			display: flex;
			align-items: center;
			justify-content: center;

			&:before {
				content: '';
				position: absolute;
				top: -13px;
				right: 0;

				width: 0;
				height: 0;
				border-bottom: 13px solid rgba(0, 0, 0, .4);
				border-left: 20px solid transparent;
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: -30px;

				width: 0;
				height: 0;
				border-bottom: 20px solid rgba(0, 0, 0, .4);
				border-left: 30px solid transparent;
			}
		}
	}
}
