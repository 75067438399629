
























































































































































































































































































.fd-basic-config-view {
	font-family: OpenSans, sans-serif;
	color: #fff;
	margin-bottom: 25px;

	h2 {
		font-size: 1.4rem;
		padding-bottom: 4px;
		margin: 10px 0 0;
		border-bottom: 1px solid #333;
	}

	.head .separator {
		&:after {
			content: "-";
		}
		margin: 0 5px;
	}
}
