




































































































































.fd-yield-line-module {
	width: 300px;
	height: 20px;
	position: relative;

	.line,
	.chunk {
		position: absolute;
	}

	.line {
		width: 100%;
		height: 2px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		background-color: black;
	}

	.chunk {
		width: 10px;
		height: 10px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		background-color: red;
		border-radius: 50%;

		animation: fd-yield-line-chunk-movement 4s linear infinite;

		&:nth-of-type(1) {
			animation-delay: -2s;
		}
		&:nth-of-type(2) {
			animation-delay: -1.333s;
		}
		&:nth-of-type(3) {
			animation-delay: -0.666s;
		}
		&:nth-of-type(4) {
			animation-delay: 0s;
		}
	}
}

@keyframes fd-yield-line-chunk-movement {
	0% {
		left: 0%;
		opacity: 0;
		transform: translate3d(0, -50%, 0);
	}
	5% {
		opacity: 1;
	}
	95% {
		opacity: 1;
	}
	100% {
		left: 100%;
		opacity: 0;
		transform: translate3d(-100%, -50%, 0);
	}
}
