




















































































































































































































































































































.fd-file-chooser {
	.fd-tabbed-pane-view {
		width: 100%;
		height: 400px;

		display: flex;

		.tabs {
			flex: 0 0 150px;
			border-right: 2px solid #c9c9c9;
			margin-right: 15px;

			.tab {
				padding: 7px 10px;
				cursor: pointer;

				&.selected {
					background: #c9c9c9;
					cursor: default;
				}
			}
		}

		.panes {
			flex: 1;
			overflow-y: auto;
		}
	}

	.fd-upload-button {
		order: -1;
		margin-left: 0 !important;
		background: #31b0d5;
	}

	.fd-separator {
		flex: 1;
	}

	.fd-pane-view {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 5px;
		padding: 5px;

		.no-files-warning {
			grid-column: 1 / span 5;
			font-size: larger;
		}

		&.color {
			grid-template-columns: repeat(10, 1fr);

			.label {
				margin-top: -14px;

				.size {
					display: none;
				}
			}
		}
	}
}
