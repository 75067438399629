


























.fd-text-input, .fd-textarea-input {
	width: 100%;

	background: hsla(0, 0%, 100%, .2);
	color: #fff;
	font-size: 11px;
	padding: 4px 7px;
	outline: none;
	border: 0;
	border-radius: 2px;
}
