

















































































.fd-config-item.File {
	flex-direction: column;

	& > * {
		width: 100% !important;

		&.label {
			margin-bottom: 3px;
		}
	}
}
.fd-config-item.File .fd-file-input {
	width: 100%;
	background-image: url('../img/colorpicker_colorbg.png');
	cursor: pointer;

	.preview {
		height: 150px;
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;

		.info {
			color: #000;
			background: hsla(0, 0%, 100%, 0.8);
			padding: 5px;
			font-size: 15px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: stretch;

			& > * {
				text-align: center;
			}

			.name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.label {
			flex: 0 0 30px;
			background: rgba(0, 0, 0, 0.6);

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
