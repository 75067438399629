
































.fd-watermark-module-root {
	z-index: 9999 !important;
}
.fd-watermark-module {
	font-family: OpenSans, sans-serif;
	font-size: 0.5em;
	text-shadow: 0 0 10px rgba(0,0,0,0.8);
	text-align: center;
	color: white;

	&:before {
		content: 'solarfox';
		position: relative;
		top: 0.5em;
		font-family: Agency;
		font-weight: bold;
		font-size: 3em;
	}
}
