















































































































.fd-style-module-root {
	height: auto !important;
}

.fd-style-module {
	.background {
		width: 100%;
		display: block;
	}

	.wrapper {
		position: absolute;
	}
}
