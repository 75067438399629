































.fd-config-item.Textarea {
	flex-direction: column;

	& > * {
		width: 100% !important;
	}
}
.fd-textarea-input {
	min-width: 100%;
	min-height: 80px;
}
