


























































.fd-export-modal-view {
	.body {
		flex-direction: column;
	}

	input {
		padding: 6px 12px;
	}

	.invisible {
		position: absolut;
		pointer-events: none;
		opacity: 0;
	}
}
