











.fd-config-item.Info {
	.label {
		display: none;
	}
	.input {
		flex: 0 0 100% !important;
	}
}

.fd-info {
	width: 100%;
	font-size: 12px;
}
