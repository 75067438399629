
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.fd-module {
	&[data-active='true'] {
		z-index: 99 !important;
		cursor: move;
	}

	.fd-decorator {
		box-sizing: border-box;

		position: absolute;
		top: -6px;
		left: -6px;
		width: calc(100% + 12px);
		height: calc(100% + 12px);
		z-index: 30;

		.resize,
		.rotate {
			display: none;

			position: absolute;
			width: 10px;
			height: 10px;
			background: #eee;
			border: 1px solid #000;
			border-radius: 5px;
			top: 50%;
			left: 50%;
			margin-top: -5px;
			margin-left: -5px;

			&:before {
				content: '';
				position: absolute;
				top: -10px;
				bottom: -10px;
				left: -10px;
				right: -10px;
			}
		}

		.rotate {
			background: green;
			cursor: url('../../img/cursor_rotate.png') 10 10, auto;
			top: -30px;

			&:after {
				content: '';
				position: absolute;
				top: 9px;
				left: 50%;
				width: 1px;
				margin-left: -1px;
				height: 22px;
				background: #333;
			}
		}

		.resize {
			&.t {
				top: -4px;
			}
			&.b {
				top: initial;
				bottom: -6px;
			}
			&.l {
				left: -4px;
			}
			&.r {
				left: initial;
				right: -6px;
			}

			&.t.l {
				cursor: nw-resize;
			}
			&.t {
				cursor: n-resize;
			}
			&.t.r {
				cursor: ne-resize;
			}
			&.l {
				cursor: w-resize;
			}
			&.r {
				cursor: e-resize;
			}
			&.b.l {
				cursor: sw-resize;
			}
			&.b {
				cursor: s-resize;
			}
			&.b.r {
				cursor: se-resize;
			}
		}

		.fd-module-button:hover {
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			cursor: pointer;
		}
		.fd-module-button {
			position: absolute;
			width: 40px;
			height: 40px;
			margin: 0;
			padding: 5px;
			font-size: 20px;
			border: none;
			border-radius: 0;
			text-align: center;
			color: hsla(0, 0, 255, 0.4);
			background: rgba(0, 0, 0, 0.2);
			.fd-module-button-icon {
				font-family: icomoon;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				letter-spacing: 0;
				-webkit-font-feature-settings: 'liga';
				-moz-font-feature-settings: 'liga=1';
				-moz-font-feature-settings: 'liga';
				-ms-font-feature-settings: 'liga' 1;
				-o-font-feature-settings: 'liga';
				font-feature-settings: 'liga';
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
	}

	&:hover > .fd-decorator {
		border: 3px dashed #4affff;
		filter: drop-shadow(0 0 1px black);
	}

	&[data-active='true'] > .fd-decorator,
	&.highlight > .fd-decorator {
		border: 3px dashed #4affff;
		filter: unset;
	}

	.fd-decorator[logo='true'] {
		border: none;
	}

	&[data-active='true'] > .fd-decorator {
		top: 0px;
		left: 0px;
		width: calc(100% + 0px);
		height: calc(100% + 0px);

		.resize,
		.rotate {
			display: block;
		}
	}
}
