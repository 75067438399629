


















































































































































































.fd-window-view {
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

	display: flex;
	flex-direction: column;

	font-family: OpenSans, sans-serif;

	.resizables {
		position: absolute;
		top: -5px;
		left: -5px;
		right: -5px;
		bottom: -5px;

		pointer-events: none;

		&.disable * {
			pointer-events: none !important;
		}

		.resizable {
			position: absolute;
			pointer-events: all;

			&.t.l {
				top: 0;
				left: 0;
				width: 8px;
				height: 8px;

				right: initial;
				bottom: initial;

				cursor: nwse-resize;
			}

			&.t {
				top: 0;
				left: 8px;
				right: 8px;
				height: 6px;

				cursor: ns-resize;
			}

			&.t.r {
				top: 0;
				right: 0;
				width: 8px;
				height: 8px;

				left: initial;
				bottom: initial;

				cursor: nesw-resize;
			}

			&.l {
				left: 0;
				top: 8px;
				bottom: 8px;
				width: 6px;

				cursor: ew-resize;
			}

			&.r {
				right: 0;
				top: 8px;
				bottom: 8px;
				width: 6px;

				cursor: ew-resize;
			}

			&.b.l {
				bottom: 0;
				left: 0;
				width: 8px;
				height: 8px;

				top: initial;
				right: initial;

				cursor: nesw-resize;
			}

			&.b {
				bottom: 0;
				left: 8px;
				right: 8px;
				height: 6px;

				cursor: ns-resize;
			}

			&.b.r {
				bottom: 0;
				right: 0;
				width: 8px;
				height: 8px;

				top: initial;
				left: initial;

				cursor: nwse-resize;
			}
		}
	}

	.header {
		background: rgba(0, 0, 0, .9);

		display: flex;
		color: white;

		.title {
			flex: 1;
			font-size: 14px;
			padding: 1px 6px;

			cursor: default;

			i {
				margin-right: 5px;
			}
		}

		.close {
			border: none;
			outline: none;
			background: none;
			color: white;
			padding: 6px;
			font-size: 10px;
			cursor: pointer;
		}

	}

	.body {
		flex: 1;
	}
}
