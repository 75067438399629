

































































































@keyframes fd_sun_module_ray_rotation_anim {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -360deg);
	}
}
@keyframes fd_sun_module_ray_rotation_anim_back {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 360deg);
	}
}
@keyframes fd_sun_module_ray_flow_anim {
	0% {
		opacity: 0.5;
		background-position: 50% 50%;
	}
	50% {
		opacity: 1;
		background-position: 70% 70%;
	}
	100% {
		opacity: 0.5;
		background-position: 50% 50%;
	}
}
@keyframes fd_sun_module_ray_flow_only_opacity_anim {
	0% {
		opacity: 0.1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.1;
	}
}

.fd-sun-module {
	position: relative;

	.abs-h-v-centered {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.pointer-events-none {
		pointer-events: none;
	}

	.sun {
		@extend .abs-h-v-centered;
		@extend .pointer-events-none;
		width: 500%;
		height: 500%;
		z-index: 20;

		border-radius: 50%;
		background: radial-gradient(
			#fff 5%,
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2),
			transparent 60%,
			transparent
		);
	}

	.ray-box {
		@extend .abs-h-v-centered;
		@extend .pointer-events-none;
		z-index: 10;
		width: 2000%;
		height: 2000%;
		opacity: 0.25;
		overflow: hidden;
		border-radius: 50%;

		animation: fd_sun_module_ray_rotation_anim 240s linear infinite;
	}

	.ray {
		position: absolute;
		left: 50%;
		top: 50%;

		width: 100%;
		height: 100%;
		transform-origin: left top;

		.inner {
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			transform: rotate3d(0, 0, 1, 45deg);
			transform-origin: left top;
			will-change: opacity, background-position;
			animation: fd_sun_module_ray_flow_anim 30s ease infinite;

			background: linear-gradient(to bottom right,
				transparent 27%,
				#fff 35%,
				transparent 45%,
				transparent
			);
			background-size: 200% 200%;
			background-position: 50% 50%;
			opacity: 1;
		}

		$rays: 9;
		@for $i from 0 to $rays {
			&.ray#{$i} {
				transform: rotate3d(0, 0, 1, 0deg + 360 / $rays * $i)
					scale3d(random() * 0.4 + 0.2, 0.7, 1);

				.inner {
					animation-delay: random(30) * -1s;
					animation-duration: random(10) * 1s + 25s;
				}
			}
		}
	}
}
