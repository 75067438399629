




































































































































































































































































.fd-toolbar-view {
	background: black;

	display: flex;
	flex-direction: column;

	.separator {
		height: 1px;
		margin: 5px 6px;
		background: #333;
	}
}
