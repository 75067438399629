























































































.fd-layer-view {
	.body {
		border-bottom: 1px solid #333;
		.module {
			-moz-user-select: -moz-none;
			-khtml-user-select: none;
			-webkit-user-select: none;
			display: flex;
			justify-content: space-between;
			margin: 0 -7px;
			padding: 5px 7px;
			height: 25px;
			font-size: 12px;
			cursor: pointer;

			&:hover {
				background: #eee;
				color: #000;
			}

			.layer {
				flex-basis: 27px;
				text-align: center;
				padding-right: 8px;
			}

			.icon {
				flex-basis: 23px;
				text-align: center;
			}

			.name {
				flex: 1;
				margin: 0 15px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
