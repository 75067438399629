























.complex-list-item-view {
	display: flex;
	align-items: center;
	padding: 4px;
	cursor: pointer;

	&:hover, &[selected] {
		background: #ddd;
		color: #000;
	}

	&[selected] {
		cursor: initial;
	}

	.icon {
		font-size: 18px;
		flex: 0;
	}

	.label {
		flex: 1;
		font-size: 14px;
		padding-left: 8px;
	}

	.actions {
		font-size: 12px;

		.action {
			height: 24px;
			width: 24px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				color: #fff;
    			background: #000;
			}
		}
	}
}
