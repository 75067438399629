

















































































.fd-watermark-module-root {
	.fd-module-button-wrench {
		top: -10px !important;
	}
	.fd-module-button-bin {
		top: 34px !important;
	}
}
