





























































































































































































































































































































.fd-module {
	.fd-decorator {
		.resize-module {
			display: none;

			position: absolute;
			width: 10px;
			height: 10px;
			background: #eee;
			border: 1px solid #000;
			border-radius: 5px;
			margin-left: -9px;
			margin-top: -9px;
			&:before {
				content: '';
				position: absolute;
				top: -10px;
				bottom: -10px;
				left: -10px;
				right: -10px;
			}
			&.r {
				cursor: e-resize;
			}
			&.b {
				cursor: s-resize;
			}
			&.m {
				cursor: move;
			}
		}
	}
	&[data-active='true'] > .fd-decorator {
		.resize-module {
			display: block;
		}
	}
}
