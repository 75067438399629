












































































.fd-counter-module {
  display: flex !important;
  overflow: hidden;

  color: white;

  .digit {
    flex: 1;

    transition: transform .6s;
    will-change: transform;
  }

  .digit.pos-z {
    margin-right: 0;
  }

  .komma, .dig-part {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pos-0, .pos-z .dig-part {
    background: red;
  }

  .dig-part {
    width: 100%;
    height: 100%;

    background: black;
  }

  .digit.pos-z,
  .digit.pos-a,
  .digit.pos-b,
  .digit.pos-c,
  .digit.pos-d,
  .digit.pos-e,
  .digit.pos-f {
    &[value="0"] {
      transform: translateY(0);
    }
    &[value="1"] {
      transform: translateY(-100%);
    }
    &[value="2"] {
      transform: translateY(-200%);
    }
    &[value="3"] {
      transform: translateY(-300%);
    }
    &[value="4"] {
      transform: translateY(-400%);
    }
    &[value="5"] {
      transform: translateY(-500%);
    }
    &[value="6"] {
      transform: translateY(-600%);
    }
    &[value="7"] {
      transform: translateY(-700%);
    }
    &[value="8"] {
      transform: translateY(-800%);
    }
    &[value="9"] {
      transform: translateY(-900%);
    }
  }
}
