

















































































































































.fd-animation-modal-view {

	table {
		text-align: center;
		border-spacing: 3em 0;
		thead {
			padding: 0 0 0 0;
		}
		tbody {
		.fd-animation-list-input {
				.fd-animation-list-item-view {
					.slider {
						width: 600px;
						background-color: #f0f0f0;
						.move {
							background-color: #fcbf07;
						}
						.move::after {
							background-color: white;
						}
						.move::before {
							background-color: white;
						}
					}
				}
			}
		}
		th, td {
			canvas {
				margin-left: 41px;
			}
		}
		td:last-child {
			width: 268px;
		}
		th:last-child {
			width: 268px;
		}
	}
}

