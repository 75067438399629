


























































$yellow: #fec107;

.fd-tutorial-window {
	input,
	select {
		display: block;
		padding: 6px 12px;
		font-size: 14px;
		color: #555;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		width: 100%;
		outline: none;
		margin: 0;
		box-sizing: border-box;
	}

	.window > .body {
		flex-direction: column;

		.tutorial-window {
			flex: 1;

			.head {
				margin: 10px 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.body {
				display: flex;
				align-items: center;
				justify-content: center;

				input,
				select {
					font-family: 'Courier New', Consolas, monospace;
				}

				button {
					width: initial;
					&.primary {
						background: $yellow;
					}
					color: white;
					font-size: 0.9em;
					text-transform: uppercase;
					text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					margin: 10px 10px;
					padding: 10px 55px 10px 55px;
				}
			}
		}
	}
}
