

































































































































































































































.fd-placeholder-module {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: rgba(0, 0, 0, 0.6);

	.info {
		font-family: OpenSans, sans-serif;
		margin: 25px 40px;
		font-size: 24px;
	}
}
