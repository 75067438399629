






































































.fd-config-item.List {
	flex-direction: column;

	& > * {
		width: 100% !important;
	}
}
.fd-list-input {
	width: 100%;

	.search {
		margin: 4px 0 2px;
	}

	.scrollview {
		margin-top: 4px;
		max-height: 310px;
		overflow-y: auto;

		&.noSpace {
			margin-top: -6px;
		}
	}
}
