

















































































.fd-number-input {
	width: 100%;

	background: hsla(0, 0%, 100%, .2);
	color: #fff;
	font-family: monospace;
	font-size: 10px;
	padding: 4px 7px;
	outline: none;
	border: 0;
	border-radius: 2px;

	&[readonly] {
		background: none;
		border: 1px solid hsla(0, 0%, 100%, .2);
	}
}
