












































































































































































































































































































































































































































































.fd-main-nav-view {
	background: black;

	display: flex;
	flex-direction: column;

	.separator {
		height: 1px;
		margin: 8px 7px 3px;
		background: #333;
	}
}
