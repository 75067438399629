@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/open_sans/opensans-regular-webfont.eot');
  src: url('fonts/open_sans/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/opensans-regular-webfont.woff2') format('woff2'),
       url('fonts/open_sans/opensans-regular-webfont.woff') format('woff'),
       url('fonts/open_sans/opensans-regular-webfont.ttf') format('truetype'),
       url('fonts/open_sans/opensans-regular-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  font-style: normal;
  src: url('fonts/open_sans/bold/opensans-bold-webfont.eot');
  src: url('fonts/open_sans/bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/bold/opensans-bold-webfont.woff2') format('woff2'),
       url('fonts/open_sans/bold/opensans-bold-webfont.woff') format('woff'),
       url('fonts/open_sans/bold/opensans-bold-webfont.ttf') format('truetype'),
       url('fonts/open_sans/bold/opensans-bold-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  font-style: italic;
  src: url('fonts/open_sans/bold-italic/opensans-bolditalic-webfont.eot');
  src: url('fonts/open_sans/bold-italic/opensans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/bold-italic/opensans-bolditalic-webfont.woff2') format('woff2'),
       url('fonts/open_sans/bold-italic/opensans-bolditalic-webfont.woff') format('woff'),
       url('fonts/open_sans/bold-italic/opensans-bolditalic-webfont.ttf') format('truetype'),
       url('fonts/open_sans/bold-italic/opensans-bolditalic-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 800;
  font-style: normal;
  src: url('fonts/open_sans/extrabold/opensans-extrabold-webfont.eot');
  src: url('fonts/open_sans/extrabold/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/extrabold/opensans-extrabold-webfont.woff2') format('woff2'),
       url('fonts/open_sans/extrabold/opensans-extrabold-webfont.woff') format('woff'),
       url('fonts/open_sans/extrabold/opensans-extrabold-webfont.ttf') format('truetype'),
       url('fonts/open_sans/extrabold/opensans-extrabold-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 800;
  font-style: italic;
  src: url('fonts/open_sans/extrabold-italic/opensans-extrabolditalic-webfont.eot');
  src: url('fonts/open_sans/extrabold-italic/opensans-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/extrabold-italic/opensans-extrabolditalic-webfont.woff2') format('woff2'),
       url('fonts/open_sans/extrabold-italic/opensans-extrabolditalic-webfont.woff') format('woff'),
       url('fonts/open_sans/extrabold-italic/opensans-extrabolditalic-webfont.ttf') format('truetype'),
       url('fonts/open_sans/extrabold-italic/opensans-extrabolditalic-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  font-style: italic;
  src: url('fonts/open_sans/italic/opensans-italic-webfont.eot');
  src: url('fonts/open_sans/italic/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/italic/opensans-italic-webfont.woff2') format('woff2'),
       url('fonts/open_sans/italic/opensans-italic-webfont.woff') format('woff'),
       url('fonts/open_sans/italic/opensans-italic-webfont.ttf') format('truetype'),
       url('fonts/open_sans/italic/opensans-italic-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  font-style: normal;
  src: url('fonts/open_sans/light/opensans-light-webfont.eot');
  src: url('fonts/open_sans/light/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/light/opensans-light-webfont.woff2') format('woff2'),
       url('fonts/open_sans/light/opensans-light-webfont.woff') format('woff'),
       url('fonts/open_sans/light/opensans-light-webfont.ttf') format('truetype'),
       url('fonts/open_sans/light/opensans-light-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  font-style: italic;
  src: url('fonts/open_sans/light-italic/opensans-lightitalic-webfont.eot');
  src: url('fonts/open_sans/light-italic/opensans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/light-italic/opensans-lightitalic-webfont.woff2') format('woff2'),
       url('fonts/open_sans/light-italic/opensans-lightitalic-webfont.woff') format('woff'),
       url('fonts/open_sans/light-italic/opensans-lightitalic-webfont.ttf') format('truetype'),
       url('fonts/open_sans/light-italic/opensans-lightitalic-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  font-style: normal;
  src: url('fonts/open_sans/semibold/opensans-semibold-webfont.eot');
  src: url('fonts/open_sans/semibold/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/semibold/opensans-semibold-webfont.woff2') format('woff2'),
       url('fonts/open_sans/semibold/opensans-semibold-webfont.woff') format('woff'),
       url('fonts/open_sans/semibold/opensans-semibold-webfont.ttf') format('truetype'),
       url('fonts/open_sans/semibold/opensans-semibold-webfont.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  font-style: italic;
  src: url('fonts/open_sans/semibold-italic/opensans-semibolditalic-webfont.eot');
  src: url('fonts/open_sans/semibold-italic/opensans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/open_sans/semibold-italic/opensans-semibolditalic-webfont.woff2') format('woff2'),
       url('fonts/open_sans/semibold-italic/opensans-semibolditalic-webfont.woff') format('woff'),
       url('fonts/open_sans/semibold-italic/opensans-semibolditalic-webfont.ttf') format('truetype'),
       url('fonts/open_sans/semibold-italic/opensans-semibolditalic-webfont.svg') format('svg');
}
