





























































































.fd-flip-animation {
  &.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
  }

  &.flipOutX {
    animation-duration: 0.75s;
    animation-name: flipOutX;
    backface-visibility: visible !important;
  }
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0
  }
}
