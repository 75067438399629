



















































































































































































































































































































































































































































































































































































































































































.fd-sub-nav-view.TemplatesView {
	.scroller {
		overflow: hidden;
	}
}
.fd-templates-view {
	flex: 1;
	margin: 0;

	display: flex;
	flex-direction: column;

	& > .body {
		flex: 1;
		display: flex;

		.col {
			flex: 1;
			padding: 0 7px;

			display: flex;
			flex-direction: column;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			&:not(:last-child) {
				border-right: 1px solid #333;
			}
		}

		.scroll-container {
			margin: 6px 0;
			flex: 1;
			position: relative;
		}

		.scroll-view {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
		}

		.preview-list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 6px;
			align-content: start;
			align-items: start;

			.Preview {
				padding: 0;
				border: none;
				user-select: none;
				cursor: pointer;

				&.selected {
					border: 2px solid #ddd;
				}
			}
		}
	}
}
