@font-face {
  font-family: 'Agency';
  font-weight: bold;
  font-style: normal;
  src: url('fonts/Agency/bold/agency-bold-webfont.eot');
  src: url('fonts/Agency/bold/agency-bold-webfont.eot#iefix') format('embedded-opentype'),
       url('fonts/Agency/bold/agency-bold-webfont.woff2') format('woff2'),
       url('fonts/Agency/bold/agency-bold-webfont.woff') format('woff'),
       url('fonts/Agency/bold/agency-bold-webfont.ttf') format('truetype'),
       url('fonts/Agency/bold/agency-bold-webfont.svg') format('svg');
}
