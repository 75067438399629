
































.fd-unlocked-template-modal-view {
	.body {
		flex-direction: column;
	}
}
