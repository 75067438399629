






































































.fd-config-item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	font-size: 11px;

	padding: 6px 0;
	border-bottom: 1px solid #333;

	.label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}

	.help {
		font-size: 13px;
		padding: 0 5px;
		cursor: help;
	}

	.input {
		display: flex;
		flex: 0 0 50%;
	}

	.extra {
		flex: 0 0 100%;
		margin-top: 10px;
		border-top: 1px dashed #555;
		padding-top: 3px;

		border-left: 1px dashed #555;
		padding-left: 15px;

		padding-bottom: 5px;
		margin-bottom: 10px;
	}
}
