





















































































































































.fd-raw .version {
	.label {
		display: block;
	}

	.version {
		font-family: Consolas, 'Courier New', Courier, monospace;
		font-size: 16px;
		text-align: center;
	}
}

.hint {
	font-size: 12px !important;
	margin: 10px 0px;
	.hint-shortcut {
		width: fit-content;
		padding: 2px 4px;
		font-size: 90%;
		color: #fff;
		background-color: #333;
		border-radius: 3px;
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
		margin-bottom: 2px;
	}
	.hint-info {
		padding-left: 5px;
	}
}
