






























































.fd-toolbar-item-view {
	background: none;
	border: none;
	outline: none;
	padding: 6px 0;
	font-size: 16px;
	color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	.fd-toolbar-item-hint {
		position: absolute;
		background-color: rgb(249, 200, 0);
		color: black;
		right: 43px;
		width: auto;
		height: 30px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		font-weight: bold;
		border-radius: 3px;
		box-shadow: 2px 2px 15px;
		margin: 0 auto;
	}
	
	.fd-toolbar-item-hint-text {
		float: left;
		height: 24px;
		text-align: center;
		line-height: 24px;
		vertical-align: center;
		white-space: nowrap;
		overflow: hidden;
		padding: 0 5px 0 5px;
	}

	.fd-toolbar-item-hint-arrow {
		border-radius: 2px;
		right: -9px;
		top: 4px;
		position: absolute;
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid rgb(249, 200, 0);
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}
