






























.fd-group-view {
	margin-top: 15px;
	margin-bottom: 30px;

	.head {
		display: flex;
		align-items: center;
		font-size: 12px;
		cursor: pointer;

		.icon {
			font-size: 10px;
			width: 20px;
		}
	}

	.body {
		margin-top: 3px;
		border-top: 1px dashed #555;
		padding-top: 5px;
		padding-left: 5px;
	}
}
