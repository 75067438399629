















































































































$yellow: #fec107;

.fd-modal-view {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: rgba(0, 0, 0, 0.5);

	font-family: OpenSans, sans-serif;

	display: flex;
	justify-content: center;
	align-items: center;

	& > .window {
		background: white;
		border-radius: 6px;
		overflow: hidden;
		box-shadow: 0 0 10px rgba(0,0,0,0.5);

		& > .head {
			background: $yellow;
			color: #fff;
    		font-size: 0.9em;
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

			display: flex;
			align-items: center;

			& > :not(.close):not(i) {
				flex: 1;
				padding: 6px 0 6px 16px;
			}

			& > i {
				padding: 6px 0 6px 16px;
			}

			& > .close {
				margin: 0;
				height: 31px;
				flex: 0 0 30px;
				background: none;
				border: none;
				outline: none;
				color: white;
    			font-size: 0.8em;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

				cursor: pointer;
			}
		}

		& > .body {
			padding: 15px 15px 0;
			font-size: 14px;
			display: flex;
			justify-content: center;
			text-align: justify;

			max-height: 60vh;
    		overflow-y: auto;
		}

		& > .footer {
			padding: 15px;
			text-align: center;
			display: flex;
			justify-content: flex-end;

			button {
				flex: 0 1 200px;
				margin-left: 10px;

				color: white;
				font-size: 0.8em;
				text-transform: uppercase;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

				&.primary {
					background: $yellow;
				}

				&[disabled="disabled"] {
					cursor: not-allowed;
				}

				i {
					margin-right: 5px;
				}
			}
		}
	}
}
