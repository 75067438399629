








































































































.fd-color-input {
	display: flex;
	flex: 1;

	.preview {
		position: relative;
		width: 30px;
		margin-left: 3px;
		border: 1px solid #333;
		border-radius: 3px;
		cursor: pointer;

		background-image: url('../img/colorpicker_colorbg.png');

		& > div, & > i {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		& > i {
			top: 1px;
			left: 2px;
			pointer-events: none;
		}

		.color-picker-arrow {
			position: absolute;
			left: initial;
			right: -10px;
			border-style: solid;
			border-width: 10px 10px 10px 0;
			border-color: transparent #fff;
			z-index: 10;
			pointer-events: none;
		}
	}
}
