







































































































































































@keyframes marquee {
	0% { transform: translateX(0); }
	100% { transform: translateX(-100%); }
}

.fd-feed-module-root {
	height: auto !important;
}

.fd-feed-module {
	display: block !important;
	overflow: hidden;

	.marqee {
		position: relative;
		white-space: nowrap;
		float: left;
		animation: marquee 0s linear infinite;
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
