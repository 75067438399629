















































































































































































































.fd-infobar-view {
	*:not(i) {
		font-family: monospace;
		font-size: 12px;
	}

	display: flex;

	color: #fff;
	background: #000;
	padding: 1px 20px;

	.fd-module-info,
	.fd-mouse-info {
		display: flex;
		align-items: flex-end;
	}

	.fd-mouse-info {
		margin-left: 80px;
	}

	.fd-input-group {
		& + .fd-input-group {
			margin-left: 10px;
		}

		input {
			color: #fff;
			background: none;
			border: none;
			border-radius: 0;
			padding: 0;
			margin: 0;
			outline: none;

			width: 80px;

			border-bottom: 1px dashed transparent;
			&:hover:not([readonly]) {
				border-bottom: 1px dashed white;
			}
		}
	}

	.fd-value-group {
		& + .fd-value-group {
			margin-left: 10px;
		}

		.fd-value {
			display: inline-block;
			width: 50px;
		}
	}

	.hidden {
		opacity: 0;
		pointer-events: none;
	}
}
