








































.fd-button-group {
	width: 100%;

	display: flex;

	.fd-button {
		margin-left: 5px;
		padding: 4px 0;

		&:first-child {
			margin-left: 0;
		}

		i {
			margin: 0;
		}
	}
}
