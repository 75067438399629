











































































































.fd-tutorial {
	.fd-tutorial-item-hint {
		font-family: sans-serif, Arial, Verdana, 'Trebuchet MS';
		position: absolute;
		background-color: rgb(249, 200, 0);
		color: black;
		width: 250px;
		height: fit-content;
		justify-content: center;
		align-items: center;
		font-size: 11px;
		font-weight: bold;
		border-radius: 3px;
		box-shadow: 2px 2px 15px;
		margin: auto auto;
	}
	.fd-tutorial-item-hint-text {
		.fd-tutorial-item-hint-text-header {
			line-height: 30px;
		}
		line-height: 15px;
		vertical-align: center;
		width: 100%;
		padding: 0 5px 0 5px;
		&.fd-tutorial-item-hint-text-title {
			width: 10px;
		}
		.cross {
			color: white;
			float: right;
			padding: 8px 15px 0px 0px;
			cursor: pointer;
		}
	}
	.fd-tutorial-item-hint-arrow {
		&.left {
			left: -9px;
			border-right: 10px solid rgb(249, 200, 0);
		}
		&.right {
			left: 249px;
			border-left: 10px solid rgb(249, 200, 0);
		}
		&.save {
			top: 10px !important;
		}
		border-radius: 2px;
		top: 45px;
		position: absolute;
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
	}
	.fd-tutorial-item-hint-text-footer {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		button {
			padding: 5px 10px;
			border: 1px solid #000;
			border-radius: 3px;
			cursor: pointer;
			margin: 10px 0 5px 0px;
			background-color: rgb(249, 200, 0);
			outline:0;
			&.right {
				float: right;
				margin-right: 10px;
			}
		}

		button:hover {
			background-color: rgb(214, 214, 214);
		}
	}
}
