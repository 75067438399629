






























































































.fd-conetx-menu-view,
.fd-conetx-menu-view .subentrys {
	position: absolute;
	top: 0;
	left: 0;

	font-family: OpenSans, sans-serif;

	background: rgba(0,0,0,.8);
	box-shadow: 0 0 20px rgba(0,0,0,.8);
	font-size: 12px;

	display: flex;
	flex-direction: column;
}

.fd-conetx-menu-view .submenu {
	.subentrys {
		top: 0;
		left: 100%;

		display: none;
	}

	&:hover .subentrys {
		display: flex;
	}
}

.fd-conetx-menu-view .entry,
.fd-conetx-menu-view .submenu {
	color: #fff;
	padding: 7px 26px 7px 10px;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		background: #444;
	}

	.icon {
		width: 18px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-right: 7px;
	}

	.label {
		display: inline-block;
	}
}

.fd-conetx-menu-view .submenu {
	position: relative;

	i.arrow-right3 {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		font-size: 9px;
		margin-right: 4px;
	}
}

.fd-conetx-menu-view .separator {
	height: 1px;
	margin: 3px 6px;
   background: #777;
}

.fd-context-focus-checker {
	position: absolute;
	opacity: 0;
	top: -9999px;
	pointer-events: none;
}
