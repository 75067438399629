@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon/icomoon.eot?1yyhy7');
  src: url('fonts/icomoon/icomoon.eot?#iefix1yyhy7') format('embedded-opentype'),
       url('fonts/icomoon/icomoon.woff?1yyhy7') format('woff'),
       url('fonts/icomoon/icomoon.ttf?1yyhy7') format('truetype'),
       url('fonts/icomoon/icomoon.svg?1yyhy7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

i, .icon {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.lg {
    font-size: 1.5em;
    line-height: 0.66em;
    vertical-align: -25%;
  }

  &.fw {
    width: 1.3em;
    text-align: center;
  }

  &.spinner {
    animation: spin 1s infinite steps(8);
  }

  &.home:before {
    content: "\e900";
  }
  &.home2:before {
    content: "\e901";
  }
  &.home3:before {
    content: "\e902";
  }
  &.home4:before {
    content: "\e903";
  }
  &.home5:before {
    content: "\e904";
  }
  &.home6:before {
    content: "\e905";
  }
  &.home7:before {
    content: "\e906";
  }
  &.home8:before {
    content: "\e907";
  }
  &.home9:before {
    content: "\e908";
  }
  &.office:before {
    content: "\e909";
  }
  &.city:before {
    content: "\e90a";
  }
  &.newspaper:before {
    content: "\e90b";
  }
  &.magazine:before {
    content: "\e90c";
  }
  &.design:before {
    content: "\e90d";
  }
  &.pencil:before {
    content: "\e90e";
  }
  &.pencil2:before {
    content: "\e90f";
  }
  &.pencil3:before {
    content: "\e910";
  }
  &.pencil4:before {
    content: "\e911";
  }
  &.pencil5:before {
    content: "\e912";
  }
  &.pencil6:before {
    content: "\e913";
  }
  &.pencil7:before {
    content: "\e914";
  }
  &.eraser:before {
    content: "\e915";
  }
  &.eraser2:before {
    content: "\e916";
  }
  &.eraser3:before {
    content: "\e917";
  }
  &.quill:before {
    content: "\e918";
  }
  &.quill2:before {
    content: "\e919";
  }
  &.quill3:before {
    content: "\e91a";
  }
  &.quill4:before {
    content: "\e91b";
  }
  &.pen:before {
    content: "\e91c";
  }
  &.pen-plus:before {
    content: "\e91d";
  }
  &.pen-minus:before {
    content: "\e91e";
  }
  &.pen2:before {
    content: "\e91f";
  }
  &.pen3:before {
    content: "\e920";
  }
  &.pen4:before {
    content: "\e921";
  }
  &.pen5:before {
    content: "\e922";
  }
  &.marker:before {
    content: "\e923";
  }
  &.marker2:before {
    content: "\e924";
  }
  &.blog:before {
    content: "\e925";
  }
  &.blog2:before {
    content: "\e926";
  }
  &.pen6:before {
    content: "\e927";
  }
  &.brush:before {
    content: "\e928";
  }
  &.spray:before {
    content: "\e929";
  }
  &.palette:before {
    content: "\e92a";
  }
  &.palette2:before {
    content: "\e92b";
  }
  &.color-sampler:before {
    content: "\e92c";
  }
  &.toggle:before {
    content: "\e92d";
  }
  &.bucket:before {
    content: "\e92e";
  }
  &.bucket2:before {
    content: "\e92f";
  }
  &.gradient:before {
    content: "\e930";
  }
  &.eyedropper:before {
    content: "\e931";
  }
  &.eyedropper2:before {
    content: "\e932";
  }
  &.eyedropper3:before {
    content: "\e933";
  }
  &.droplet:before {
    content: "\e934";
  }
  &.droplet2:before {
    content: "\e935";
  }
  &.droplet-contrast:before {
    content: "\e936";
  }
  &.color-clear:before {
    content: "\e937";
  }
  &.paint-format:before {
    content: "\e938";
  }
  &.stamp:before {
    content: "\e939";
  }
  &.images:before {
    content: "\e93a";
  }
  &.image:before {
    content: "\e93b";
  }
  &.image2:before {
    content: "\e93c";
  }
  &.image-compare:before {
    content: "\e93d";
  }
  &.images2:before {
    content: "\e93e";
  }
  &.image3:before {
    content: "\e93f";
  }
  &.images3:before {
    content: "\e940";
  }
  &.image4:before {
    content: "\e941";
  }
  &.image5:before {
    content: "\e942";
  }
  &.image6:before {
    content: "\e943";
  }
  &.camera:before {
    content: "\e944";
  }
  &.camera2:before {
    content: "\e945";
  }
  &.camera3:before {
    content: "\e946";
  }
  &.shutter:before {
    content: "\e947";
  }
  &.headphones:before {
    content: "\e948";
  }
  &.headset:before {
    content: "\e949";
  }
  &.music:before {
    content: "\e94a";
  }
  &.music2:before {
    content: "\e94b";
  }
  &.music3:before {
    content: "\e94c";
  }
  &.music4:before {
    content: "\e94d";
  }
  &.music5:before {
    content: "\e94e";
  }
  &.music6:before {
    content: "\e94f";
  }
  &.album:before {
    content: "\e950";
  }
  &.cassette:before {
    content: "\e951";
  }
  &.tape:before {
    content: "\e952";
  }
  &.piano:before {
    content: "\e953";
  }
  &.guitar:before {
    content: "\e954";
  }
  &.trumpet:before {
    content: "\e955";
  }
  &.speakers:before {
    content: "\e956";
  }
  &.play:before {
    content: "\e957";
  }
  &.play2:before {
    content: "\e958";
  }
  &.clapboard-play:before {
    content: "\e959";
  }
  &.clapboard:before {
    content: "\e95a";
  }
  &.media:before {
    content: "\e95b";
  }
  &.presentation:before {
    content: "\e95c";
  }
  &.movie:before {
    content: "\e95d";
  }
  &.film:before {
    content: "\e95e";
  }
  &.film2:before {
    content: "\e95f";
  }
  &.film3:before {
    content: "\e960";
  }
  &.film4:before {
    content: "\e961";
  }
  &.video-camera:before {
    content: "\e962";
  }
  &.video-camera2:before {
    content: "\e963";
  }
  &.video-camera-slash:before {
    content: "\e964";
  }
  &.video-camera3:before {
    content: "\e965";
  }
  &.video-camera4:before {
    content: "\e966";
  }
  &.video-camera-slash2:before {
    content: "\e967";
  }
  &.video-camera5:before {
    content: "\e968";
  }
  &.recording:before {
    content: "\e969";
  }
  &.dice:before {
    content: "\e96a";
  }
  &.gamepad:before {
    content: "\e96b";
  }
  &.gamepad2:before {
    content: "\e96c";
  }
  &.gamepad3:before {
    content: "\e96d";
  }
  &.pacman:before {
    content: "\e96e";
  }
  &.spades:before {
    content: "\e96f";
  }
  &.clubs:before {
    content: "\e970";
  }
  &.diamonds:before {
    content: "\e971";
  }
  &.chess-king:before {
    content: "\e972";
  }
  &.chess-queen:before {
    content: "\e973";
  }
  &.chess-rock:before {
    content: "\e974";
  }
  &.chess-bishop:before {
    content: "\e975";
  }
  &.chess-knight:before {
    content: "\e976";
  }
  &.chess-pawn:before {
    content: "\e977";
  }
  &.chess:before {
    content: "\e978";
  }
  &.bullhorn:before {
    content: "\e979";
  }
  &.megaphone:before {
    content: "\e97a";
  }
  &.new:before {
    content: "\e97b";
  }
  &.connection:before {
    content: "\e97c";
  }
  &.connection2:before {
    content: "\e97d";
  }
  &.connection3:before {
    content: "\e97e";
  }
  &.connection4:before {
    content: "\e97f";
  }
  &.podcast:before {
    content: "\e980";
  }
  &.station:before {
    content: "\e981";
  }
  &.station2:before {
    content: "\e982";
  }
  &.antenna:before {
    content: "\e983";
  }
  &.satellite-dish:before {
    content: "\e984";
  }
  &.satellite-dish2:before {
    content: "\e985";
  }
  &.feed:before {
    content: "\e986";
  }
  &.podcast2:before {
    content: "\e987";
  }
  &.antenna2:before {
    content: "\e988";
  }
  &.mic:before {
    content: "\e989";
  }
  &.mic-off:before {
    content: "\e98a";
  }
  &.mic2:before {
    content: "\e98b";
  }
  &.mic-off2:before {
    content: "\e98c";
  }
  &.mic3:before {
    content: "\e98d";
  }
  &.mic4:before {
    content: "\e98e";
  }
  &.mic5:before {
    content: "\e98f";
  }
  &.book:before {
    content: "\e990";
  }
  &.book2:before {
    content: "\e991";
  }
  &.book-play:before {
    content: "\e992";
  }
  &.book3:before {
    content: "\e993";
  }
  &.bookmark:before {
    content: "\e994";
  }
  &.books:before {
    content: "\e995";
  }
  &.archive:before {
    content: "\e996";
  }
  &.reading:before {
    content: "\e997";
  }
  &.library:before {
    content: "\e998";
  }
  &.library2:before {
    content: "\e999";
  }
  &.graduation:before {
    content: "\e99a";
  }
  &.file-text:before {
    content: "\e99b";
  }
  &.profile:before {
    content: "\e99c";
  }
  &.file-empty:before {
    content: "\e99d";
  }
  &.file-empty2:before {
    content: "\e99e";
  }
  &.files-empty:before {
    content: "\e99f";
  }
  &.files-empty2:before {
    content: "\e9a0";
  }
  &.file-plus:before {
    content: "\e9a1";
  }
  &.file-plus2:before {
    content: "\e9a2";
  }
  &.file-minus:before {
    content: "\e9a3";
  }
  &.file-minus2:before {
    content: "\e9a4";
  }
  &.file-download:before {
    content: "\e9a5";
  }
  &.file-download2:before {
    content: "\e9a6";
  }
  &.file-upload:before {
    content: "\e9a7";
  }
  &.file-upload2:before {
    content: "\e9a8";
  }
  &.file-check:before {
    content: "\e9a9";
  }
  &.file-check2:before {
    content: "\e9aa";
  }
  &.file-eye:before {
    content: "\e9ab";
  }
  &.file-eye2:before {
    content: "\e9ac";
  }
  &.file-text2:before {
    content: "\e9ad";
  }
  &.file-text3:before {
    content: "\e9ae";
  }
  &.file-picture:before {
    content: "\e9af";
  }
  &.file-picture2:before {
    content: "\e9b0";
  }
  &.file-music:before {
    content: "\e9b1";
  }
  &.file-music2:before {
    content: "\e9b2";
  }
  &.file-play:before {
    content: "\e9b3";
  }
  &.file-play2:before {
    content: "\e9b4";
  }
  &.file-video:before {
    content: "\e9b5";
  }
  &.file-video2:before {
    content: "\e9b6";
  }
  &.copy:before {
    content: "\e9b7";
  }
  &.copy2:before {
    content: "\e9b8";
  }
  &.file-zip:before {
    content: "\e9b9";
  }
  &.file-zip2:before {
    content: "\e9ba";
  }
  &.file-xml:before {
    content: "\e9bb";
  }
  &.file-xml2:before {
    content: "\e9bc";
  }
  &.file-css:before {
    content: "\e9bd";
  }
  &.file-css2:before {
    content: "\e9be";
  }
  &.file-presentation:before {
    content: "\e9bf";
  }
  &.file-presentation2:before {
    content: "\e9c0";
  }
  &.file-stats:before {
    content: "\e9c1";
  }
  &.file-stats2:before {
    content: "\e9c2";
  }
  &.file-locked:before {
    content: "\e9c3";
  }
  &.file-locked2:before {
    content: "\e9c4";
  }
  &.file-spreadsheet:before {
    content: "\e9c5";
  }
  &.file-spreadsheet2:before {
    content: "\e9c6";
  }
  &.copy3:before {
    content: "\e9c7";
  }
  &.copy4:before {
    content: "\e9c8";
  }
  &.paste:before {
    content: "\e9c9";
  }
  &.paste2:before {
    content: "\e9ca";
  }
  &.paste3:before {
    content: "\e9cb";
  }
  &.paste4:before {
    content: "\e9cc";
  }
  &.stack:before {
    content: "\e9cd";
  }
  &.stack2:before {
    content: "\e9ce";
  }
  &.stack3:before {
    content: "\e9cf";
  }
  &.folder:before {
    content: "\e9d0";
  }
  &.folder-search:before {
    content: "\e9d1";
  }
  &.folder-download:before {
    content: "\e9d2";
  }
  &.folder-upload:before {
    content: "\e9d3";
  }
  &.folder-plus:before {
    content: "\e9d4";
  }
  &.folder-plus2:before {
    content: "\e9d5";
  }
  &.folder-minus:before {
    content: "\e9d6";
  }
  &.folder-minus2:before {
    content: "\e9d7";
  }
  &.folder-check:before {
    content: "\e9d8";
  }
  &.folder-heart:before {
    content: "\e9d9";
  }
  &.folder-remove:before {
    content: "\e9da";
  }
  &.folder2:before {
    content: "\e9db";
  }
  &.folder-open:before {
    content: "\e9dc";
  }
  &.folder3:before {
    content: "\e9dd";
  }
  &.folder4:before {
    content: "\e9de";
  }
  &.folder-plus3:before {
    content: "\e9df";
  }
  &.folder-minus3:before {
    content: "\e9e0";
  }
  &.folder-plus4:before {
    content: "\e9e1";
  }
  &.folder-minus4:before {
    content: "\e9e2";
  }
  &.folder-download2:before {
    content: "\e9e3";
  }
  &.folder-upload2:before {
    content: "\e9e4";
  }
  &.folder-download3:before {
    content: "\e9e5";
  }
  &.folder-upload3:before {
    content: "\e9e6";
  }
  &.folder5:before {
    content: "\e9e7";
  }
  &.folder-open2:before {
    content: "\e9e8";
  }
  &.folder6:before {
    content: "\e9e9";
  }
  &.folder-open3:before {
    content: "\e9ea";
  }
  &.certificate:before {
    content: "\e9eb";
  }
  &.cc:before {
    content: "\e9ec";
  }
  &.price-tag:before {
    content: "\e9ed";
  }
  &.price-tag2:before {
    content: "\e9ee";
  }
  &.price-tags:before {
    content: "\e9ef";
  }
  &.price-tag3:before {
    content: "\e9f0";
  }
  &.price-tags2:before {
    content: "\e9f1";
  }
  &.price-tag4:before {
    content: "\e9f2";
  }
  &.price-tags3:before {
    content: "\e9f3";
  }
  &.price-tag5:before {
    content: "\e9f4";
  }
  &.price-tags4:before {
    content: "\e9f5";
  }
  &.barcode:before {
    content: "\e9f6";
  }
  &.barcode2:before {
    content: "\e9f7";
  }
  &.qrcode:before {
    content: "\e9f8";
  }
  &.ticket:before {
    content: "\e9f9";
  }
  &.ticket2:before {
    content: "\e9fa";
  }
  &.theater:before {
    content: "\e9fb";
  }
  &.store:before {
    content: "\e9fc";
  }
  &.store2:before {
    content: "\e9fd";
  }
  &.cart:before {
    content: "\e9fe";
  }
  &.cart2:before {
    content: "\e9ff";
  }
  &.cart3:before {
    content: "\ea00";
  }
  &.cart4:before {
    content: "\ea01";
  }
  &.cart5:before {
    content: "\ea02";
  }
  &.cart-add:before {
    content: "\ea03";
  }
  &.cart-add2:before {
    content: "\ea04";
  }
  &.cart-remove:before {
    content: "\ea05";
  }
  &.basket:before {
    content: "\ea06";
  }
  &.bag:before {
    content: "\ea07";
  }
  &.bag2:before {
    content: "\ea08";
  }
  &.percent:before {
    content: "\ea09";
  }
  &.coins:before {
    content: "\ea0a";
  }
  &.coin-dollar:before {
    content: "\ea0b";
  }
  &.coin-euro:before {
    content: "\ea0c";
  }
  &.coin-pound:before {
    content: "\ea0d";
  }
  &.coin-yen:before {
    content: "\ea0e";
  }
  &.piggy-bank:before {
    content: "\ea0f";
  }
  &.wallet:before {
    content: "\ea10";
  }
  &.cash:before {
    content: "\ea11";
  }
  &.cash2:before {
    content: "\ea12";
  }
  &.cash3:before {
    content: "\ea13";
  }
  &.cash4:before {
    content: "\ea14";
  }
  &.credit-card:before {
    content: "\ea15";
  }
  &.credit-card2:before {
    content: "\ea16";
  }
  &.calculator:before {
    content: "\ea17";
  }
  &.calculator2:before {
    content: "\ea18";
  }
  &.calculator3:before {
    content: "\ea19";
  }
  &.chip:before {
    content: "\ea1a";
  }
  &.lifebuoy:before {
    content: "\ea1b";
  }
  &.phone:before {
    content: "\ea1c";
  }
  &.phone2:before {
    content: "\ea1d";
  }
  &.phone-slash:before {
    content: "\ea1e";
  }
  &.phone-wave:before {
    content: "\ea1f";
  }
  &.phone-plus:before {
    content: "\ea20";
  }
  &.phone-minus:before {
    content: "\ea21";
  }
  &.phone-plus2:before {
    content: "\ea22";
  }
  &.phone-minus2:before {
    content: "\ea23";
  }
  &.phone-incoming:before {
    content: "\ea24";
  }
  &.phone-outgoing:before {
    content: "\ea25";
  }
  &.phone3:before {
    content: "\ea26";
  }
  &.phone-slash2:before {
    content: "\ea27";
  }
  &.phone-wave2:before {
    content: "\ea28";
  }
  &.phone-hang-up:before {
    content: "\ea29";
  }
  &.phone-hang-up2:before {
    content: "\ea2a";
  }
  &.address-book:before {
    content: "\ea2b";
  }
  &.address-book2:before {
    content: "\ea2c";
  }
  &.address-book3:before {
    content: "\ea2d";
  }
  &.notebook:before {
    content: "\ea2e";
  }
  &.at-sign:before {
    content: "\ea2f";
  }
  &.envelop:before {
    content: "\ea30";
  }
  &.envelop2:before {
    content: "\ea31";
  }
  &.envelop3:before {
    content: "\ea32";
  }
  &.envelop4:before {
    content: "\ea33";
  }
  &.envelop5:before {
    content: "\ea34";
  }
  &.mailbox:before {
    content: "\ea35";
  }
  &.pushpin:before {
    content: "\ea36";
  }
  &.pushpin2:before {
    content: "\ea37";
  }
  &.location:before {
    content: "\ea38";
  }
  &.location2:before {
    content: "\ea39";
  }
  &.location3:before {
    content: "\ea3a";
  }
  &.location4:before {
    content: "\ea3b";
  }
  &.location5:before {
    content: "\ea3c";
  }
  &.location6:before {
    content: "\ea3d";
  }
  &.location7:before {
    content: "\ea3e";
  }
  &.compass:before {
    content: "\ea3f";
  }
  &.compass2:before {
    content: "\ea40";
  }
  &.compass3:before {
    content: "\ea41";
  }
  &.compass4:before {
    content: "\ea42";
  }
  &.compass5:before {
    content: "\ea43";
  }
  &.compass6:before {
    content: "\ea44";
  }
  &.map:before {
    content: "\ea45";
  }
  &.map2:before {
    content: "\ea46";
  }
  &.map3:before {
    content: "\ea47";
  }
  &.map4:before {
    content: "\ea48";
  }
  &.map5:before {
    content: "\ea49";
  }
  &.direction:before {
    content: "\ea4a";
  }
  &.reset:before {
    content: "\ea4b";
  }
  &.history:before {
    content: "\ea4c";
  }
  &.clock:before {
    content: "\ea4d";
  }
  &.clock2:before {
    content: "\ea4e";
  }
  &.clock3:before {
    content: "\ea4f";
  }
  &.clock4:before {
    content: "\ea50";
  }
  &.watch:before {
    content: "\ea51";
  }
  &.watch2:before {
    content: "\ea52";
  }
  &.alarm:before {
    content: "\ea53";
  }
  &.alarm-add:before {
    content: "\ea54";
  }
  &.alarm-check:before {
    content: "\ea55";
  }
  &.alarm-cancel:before {
    content: "\ea56";
  }
  &.bell:before {
    content: "\ea57";
  }
  &.bell2:before {
    content: "\ea58";
  }
  &.bell3:before {
    content: "\ea59";
  }
  &.bell-plus:before {
    content: "\ea5a";
  }
  &.bell-minus:before {
    content: "\ea5b";
  }
  &.bell-check:before {
    content: "\ea5c";
  }
  &.bell-cross:before {
    content: "\ea5d";
  }
  &.stopwatch:before {
    content: "\ea5e";
  }
  &.calendar:before {
    content: "\ea5f";
  }
  &.calendar2:before {
    content: "\ea60";
  }
  &.calendar3:before {
    content: "\ea61";
  }
  &.calendar4:before {
    content: "\ea62";
  }
  &.calendar5:before {
    content: "\ea63";
  }
  &.printer:before {
    content: "\ea64";
  }
  &.printer2:before {
    content: "\ea65";
  }
  &.printer3:before {
    content: "\ea66";
  }
  &.printer4:before {
    content: "\ea67";
  }
  &.shredder:before {
    content: "\ea68";
  }
  &.mouse:before {
    content: "\ea69";
  }
  &.mouse2:before {
    content: "\ea6a";
  }
  &.mouse-left:before {
    content: "\ea6b";
  }
  &.mouse-right:before {
    content: "\ea6c";
  }
  &.keyboard:before {
    content: "\ea6d";
  }
  &.keyboard2:before {
    content: "\ea6e";
  }
  &.typewriter:before {
    content: "\ea6f";
  }
  &.display:before {
    content: "\ea70";
  }
  &.display2:before {
    content: "\ea71";
  }
  &.display3:before {
    content: "\ea72";
  }
  &.display4:before {
    content: "\ea73";
  }
  &.laptop:before {
    content: "\ea74";
  }
  &.mobile:before {
    content: "\ea75";
  }
  &.mobile2:before {
    content: "\ea76";
  }
  &.tablet:before {
    content: "\ea77";
  }
  &.mobile3:before {
    content: "\ea78";
  }
  &.tv:before {
    content: "\ea79";
  }
  &.radio:before {
    content: "\ea7a";
  }
  &.cabinet:before {
    content: "\ea7b";
  }
  &.drawer:before {
    content: "\ea7c";
  }
  &.drawer2:before {
    content: "\ea7d";
  }
  &.drawer-out:before {
    content: "\ea7e";
  }
  &.drawer-in:before {
    content: "\ea7f";
  }
  &.drawer3:before {
    content: "\ea80";
  }
  &.box:before {
    content: "\ea81";
  }
  &.box-add:before {
    content: "\ea82";
  }
  &.box-remove:before {
    content: "\ea83";
  }
  &.download:before {
    content: "\ea84";
  }
  &.upload:before {
    content: "\ea85";
  }
  &.floppy-disk:before {
    content: "\ea86";
  }
  &.floppy-disks:before {
    content: "\ea87";
  }
  &.floppy-disks2:before {
    content: "\ea88";
  }
  &.floppy-disk2:before {
    content: "\ea89";
  }
  &.floppy-disk3:before {
    content: "\ea8a";
  }
  &.disk:before {
    content: "\ea8b";
  }
  &.disk2:before {
    content: "\ea8c";
  }
  &.usb-stick:before {
    content: "\ea8d";
  }
  &.drive:before {
    content: "\ea8e";
  }
  &.server:before {
    content: "\ea8f";
  }
  &.database:before {
    content: "\ea90";
  }
  &.database2:before {
    content: "\ea91";
  }
  &.database3:before {
    content: "\ea92";
  }
  &.database4:before {
    content: "\ea93";
  }
  &.database-menu:before {
    content: "\ea94";
  }
  &.database-add:before {
    content: "\ea95";
  }
  &.database-remove:before {
    content: "\ea96";
  }
  &.database-insert:before {
    content: "\ea97";
  }
  &.database-export:before {
    content: "\ea98";
  }
  &.database-upload:before {
    content: "\ea99";
  }
  &.database-refresh:before {
    content: "\ea9a";
  }
  &.database-diff:before {
    content: "\ea9b";
  }
  &.database-edit:before {
    content: "\ea9c";
  }
  &.database-edit2:before {
    content: "\ea9d";
  }
  &.database-check:before {
    content: "\ea9e";
  }
  &.database-arrow:before {
    content: "\ea9f";
  }
  &.database-time:before {
    content: "\eaa0";
  }
  &.database-time2:before {
    content: "\eaa1";
  }
  &.undo:before {
    content: "\eaa2";
  }
  &.redo:before {
    content: "\eaa3";
  }
  &.rotate-ccw:before {
    content: "\eaa4";
  }
  &.rotate-cw:before {
    content: "\eaa5";
  }
  &.rotate-ccw2:before {
    content: "\eaa6";
  }
  &.rotate-cw2:before {
    content: "\eaa7";
  }
  &.rotate-ccw3:before {
    content: "\eaa8";
  }
  &.rotate-cw3:before {
    content: "\eaa9";
  }
  &.flip-vertical:before {
    content: "\eaaa";
  }
  &.flip-vertical2:before {
    content: "\eaab";
  }
  &.flip-horizontal:before {
    content: "\eaac";
  }
  &.flip-horizontal2:before {
    content: "\eaad";
  }
  &.flip-vertical3:before {
    content: "\eaae";
  }
  &.flip-vertical4:before {
    content: "\eaaf";
  }
  &.angle:before {
    content: "\eab0";
  }
  &.shear:before {
    content: "\eab1";
  }
  &.unite:before {
    content: "\eab2";
  }
  &.subtract:before {
    content: "\eab3";
  }
  &.interset:before {
    content: "\eab4";
  }
  &.exclude:before {
    content: "\eab5";
  }
  &.align-left:before {
    content: "\eab6";
  }
  &.align-center-horizontal:before {
    content: "\eab7";
  }
  &.align-right:before {
    content: "\eab8";
  }
  &.align-top:before {
    content: "\eab9";
  }
  &.align-center-vertical:before {
    content: "\eaba";
  }
  &.align-bottom:before {
    content: "\eabb";
  }
  &.spread-horizontal:before {
    content: "\e601";
  }
  &.spread-vertical:before {
    content: "\e600";
  }
  &.undo2:before {
    content: "\eabc";
  }
  &.redo2:before {
    content: "\eabd";
  }
  &.forward:before {
    content: "\eabe";
  }
  &.reply:before {
    content: "\eabf";
  }
  &.reply-all:before {
    content: "\eac0";
  }
  &.bubble:before {
    content: "\eac1";
  }
  &.bubbles:before {
    content: "\eac2";
  }
  &.bubbles2:before {
    content: "\eac3";
  }
  &.bubble2:before {
    content: "\eac4";
  }
  &.bubbles3:before {
    content: "\eac5";
  }
  &.bubbles4:before {
    content: "\eac6";
  }
  &.bubble-notification:before {
    content: "\eac7";
  }
  &.bubbles5:before {
    content: "\eac8";
  }
  &.bubbles6:before {
    content: "\eac9";
  }
  &.bubble3:before {
    content: "\eaca";
  }
  &.bubble-dots:before {
    content: "\eacb";
  }
  &.bubble-lines:before {
    content: "\eacc";
  }
  &.bubble4:before {
    content: "\eacd";
  }
  &.bubble-dots2:before {
    content: "\eace";
  }
  &.bubble-lines2:before {
    content: "\eacf";
  }
  &.bubble5:before {
    content: "\ead0";
  }
  &.bubble6:before {
    content: "\ead1";
  }
  &.bubbles7:before {
    content: "\ead2";
  }
  &.bubble7:before {
    content: "\ead3";
  }
  &.bubbles8:before {
    content: "\ead4";
  }
  &.bubble8:before {
    content: "\ead5";
  }
  &.bubble-dots3:before {
    content: "\ead6";
  }
  &.bubble-lines3:before {
    content: "\ead7";
  }
  &.bubble9:before {
    content: "\ead8";
  }
  &.bubble-dots4:before {
    content: "\ead9";
  }
  &.bubble-lines4:before {
    content: "\eada";
  }
  &.bubbles9:before {
    content: "\eadb";
  }
  &.bubbles10:before {
    content: "\eadc";
  }
  &.bubble-blocked:before {
    content: "\eadd";
  }
  &.bubble-quote:before {
    content: "\eade";
  }
  &.bubble-user:before {
    content: "\eadf";
  }
  &.bubble-smiley:before {
    content: "\eae0";
  }
  &.bubble-check:before {
    content: "\eae1";
  }
  &.bubble-video:before {
    content: "\eae2";
  }
  &.bubble-link:before {
    content: "\eae3";
  }
  &.bubble-lock:before {
    content: "\eae4";
  }
  &.bubble-star:before {
    content: "\eae5";
  }
  &.bubble-heart:before {
    content: "\eae6";
  }
  &.bubble-paperclip:before {
    content: "\eae7";
  }
  &.bubble-cancel:before {
    content: "\eae8";
  }
  &.bubble-plus:before {
    content: "\eae9";
  }
  &.bubble-minus:before {
    content: "\eaea";
  }
  &.bubble-notification2:before {
    content: "\eaeb";
  }
  &.bubble-left:before {
    content: "\eaec";
  }
  &.bubble-right:before {
    content: "\eaed";
  }
  &.bubble-first:before {
    content: "\eaee";
  }
  &.bubble-last:before {
    content: "\eaef";
  }
  &.bubble-reply:before {
    content: "\eaf0";
  }
  &.bubble-forward:before {
    content: "\eaf1";
  }
  &.bubble-reply2:before {
    content: "\eaf2";
  }
  &.bubble-forward2:before {
    content: "\eaf3";
  }
  &.user:before {
    content: "\eaf4";
  }
  &.users:before {
    content: "\eaf5";
  }
  &.user-plus:before {
    content: "\eaf6";
  }
  &.user-minus:before {
    content: "\eaf7";
  }
  &.user-cancel:before {
    content: "\eaf8";
  }
  &.user-block:before {
    content: "\eaf9";
  }
  &.user-lock:before {
    content: "\eafa";
  }
  &.user-check:before {
    content: "\eafb";
  }
  &.users2:before {
    content: "\eafc";
  }
  &.user2:before {
    content: "\eafd";
  }
  &.users3:before {
    content: "\eafe";
  }
  &.user-plus2:before {
    content: "\eaff";
  }
  &.user-minus2:before {
    content: "\eb00";
  }
  &.user-cancel2:before {
    content: "\eb01";
  }
  &.user-block2:before {
    content: "\eb02";
  }
  &.user-lock2:before {
    content: "\eb03";
  }
  &.user-check2:before {
    content: "\eb04";
  }
  &.user3:before {
    content: "\eb05";
  }
  &.user4:before {
    content: "\eb06";
  }
  &.user5:before {
    content: "\eb07";
  }
  &.user6:before {
    content: "\eb08";
  }
  &.users4:before {
    content: "\eb09";
  }
  &.user-tie:before {
    content: "\eb0a";
  }
  &.user7:before {
    content: "\eb0b";
  }
  &.users5:before {
    content: "\eb0c";
  }
  &.collaboration:before {
    content: "\eb0d";
  }
  &.vcard:before {
    content: "\eb0e";
  }
  &.hat:before {
    content: "\eb0f";
  }
  &.bowtie:before {
    content: "\eb10";
  }
  &.tie:before {
    content: "\eb11";
  }
  &.hanger:before {
    content: "\eb12";
  }
  &.tshirt:before {
    content: "\eb13";
  }
  &.pants:before {
    content: "\eb14";
  }
  &.shorts:before {
    content: "\eb15";
  }
  &.sock:before {
    content: "\eb16";
  }
  &.laundry:before {
    content: "\eb17";
  }
  &.quotes-left:before {
    content: "\eb18";
  }
  &.quotes-right:before {
    content: "\eb19";
  }
  &.quotes-left2:before {
    content: "\eb1a";
  }
  &.quotes-right2:before {
    content: "\eb1b";
  }
  &.hour-glass:before {
    content: "\eb1c";
  }
  &.hour-glass2:before {
    content: "\eb1d";
  }
  &.hour-glass3:before {
    content: "\eb1e";
  }
  &.spinner:before {
    content: "\eb1f";
  }
  &.spinner2:before {
    content: "\eb20";
  }
  &.spinner3:before {
    content: "\eb21";
  }
  &.spinner4:before {
    content: "\eb22";
  }
  &.spinner5:before {
    content: "\eb23";
  }
  &.spinner6:before {
    content: "\eb24";
  }
  &.spinner7:before {
    content: "\eb25";
  }
  &.spinner8:before {
    content: "\eb26";
  }
  &.spinner9:before {
    content: "\eb27";
  }
  &.spinner10:before {
    content: "\eb28";
  }
  &.spinner11:before {
    content: "\eb29";
  }
  &.microscope:before {
    content: "\eb2a";
  }
  &.binoculars:before {
    content: "\eb2b";
  }
  &.binoculars2:before {
    content: "\eb2c";
  }
  &.search:before {
    content: "\eb2d";
  }
  &.zoom-in:before {
    content: "\eb2e";
  }
  &.zoom-out:before {
    content: "\eb2f";
  }
  &.search2:before {
    content: "\eb30";
  }
  &.zoom-in2:before {
    content: "\eb31";
  }
  &.zoom-out2:before {
    content: "\eb32";
  }
  &.search3:before {
    content: "\eb33";
  }
  &.search4:before {
    content: "\eb34";
  }
  &.zoom-in3:before {
    content: "\eb35";
  }
  &.zoom-out3:before {
    content: "\eb36";
  }
  &.search5:before {
    content: "\eb37";
  }
  &.search6:before {
    content: "\eb38";
  }
  &.enlarge:before {
    content: "\eb39";
  }
  &.shrink:before {
    content: "\eb3a";
  }
  &.enlarge2:before {
    content: "\eb3b";
  }
  &.shrink2:before {
    content: "\eb3c";
  }
  &.enlarge3:before {
    content: "\eb3d";
  }
  &.shrink3:before {
    content: "\eb3e";
  }
  &.enlarge4:before {
    content: "\eb3f";
  }
  &.shrink4:before {
    content: "\eb40";
  }
  &.enlarge5:before {
    content: "\eb41";
  }
  &.shrink5:before {
    content: "\eb42";
  }
  &.enlarge6:before {
    content: "\eb43";
  }
  &.shrink6:before {
    content: "\eb44";
  }
  &.enlarge7:before {
    content: "\eb45";
  }
  &.shrink7:before {
    content: "\eb46";
  }
  &.key:before {
    content: "\eb47";
  }
  &.key2:before {
    content: "\eb48";
  }
  &.key3:before {
    content: "\eb49";
  }
  &.key4:before {
    content: "\eb4a";
  }
  &.key5:before {
    content: "\eb4b";
  }
  &.keyhole:before {
    content: "\eb4c";
  }
  &.lock:before {
    content: "\eb4d";
  }
  &.lock2:before {
    content: "\eb4e";
  }
  &.lock3:before {
    content: "\eb4f";
  }
  &.lock4:before {
    content: "\eb50";
  }
  &.unlocked:before {
    content: "\eb51";
  }
  &.lock5:before {
    content: "\eb52";
  }
  &.unlocked2:before {
    content: "\eb53";
  }
  &.safe:before {
    content: "\eb54";
  }
  &.wrench:before {
    content: "\eb55";
  }
  &.wrench2:before {
    content: "\eb56";
  }
  &.wrench3:before {
    content: "\eb57";
  }
  &.equalizer:before {
    content: "\eb58";
  }
  &.equalizer2:before {
    content: "\eb59";
  }
  &.equalizer3:before {
    content: "\eb5a";
  }
  &.equalizer4:before {
    content: "\eb5b";
  }
  &.cog:before {
    content: "\eb5c";
  }
  &.cogs:before {
    content: "\eb5d";
  }
  &.cog2:before {
    content: "\eb5e";
  }
  &.cog3:before {
    content: "\eb5f";
  }
  &.cog4:before {
    content: "\eb60";
  }
  &.cog5:before {
    content: "\eb61";
  }
  &.cog6:before {
    content: "\eb62";
  }
  &.cog7:before {
    content: "\eb63";
  }
  &.factory:before {
    content: "\eb64";
  }
  &.hammer:before {
    content: "\eb65";
  }
  &.hammer-wrench:before {
    content: "\eb66";
  }
  &.knife:before {
    content: "\eb67";
  }
  &.screwdriver:before {
    content: "\eb68";
  }
  &.screwdriver2:before {
    content: "\eb69";
  }
  &.magic-wand:before {
    content: "\eb6a";
  }
  &.magic-wand2:before {
    content: "\eb6b";
  }
  &.pulse:before {
    content: "\eb6c";
  }
  &.pulse2:before {
    content: "\eb6d";
  }
  &.aid-kit:before {
    content: "\eb6e";
  }
  &.aid-kit2:before {
    content: "\eb6f";
  }
  &.stethoscope:before {
    content: "\eb70";
  }
  &.ambulance:before {
    content: "\eb71";
  }
  &.alarm2:before {
    content: "\eb72";
  }
  &.pandage:before {
    content: "\eb73";
  }
  &.pandage2:before {
    content: "\eb74";
  }
  &.bug:before {
    content: "\eb75";
  }
  &.bug2:before {
    content: "\eb76";
  }
  &.syringe:before {
    content: "\eb77";
  }
  &.syringe2:before {
    content: "\eb78";
  }
  &.pill:before {
    content: "\eb79";
  }
  &.construction:before {
    content: "\eb7a";
  }
  &.traffic-cone:before {
    content: "\eb7b";
  }
  &.traffic-lights:before {
    content: "\eb7c";
  }
  &.pie-chart:before {
    content: "\eb7d";
  }
  &.pie-chart2:before {
    content: "\eb7e";
  }
  &.pie-chart3:before {
    content: "\eb7f";
  }
  &.pie-chart4:before {
    content: "\eb80";
  }
  &.pie-chart5:before {
    content: "\eb81";
  }
  &.pie-chart6:before {
    content: "\eb82";
  }
  &.pie-chart7:before {
    content: "\eb83";
  }
  &.stats-dots:before {
    content: "\eb84";
  }
  &.stats-bars:before {
    content: "\eb85";
  }
  &.pie-chart8:before {
    content: "\eb86";
  }
  &.stats-bars2:before {
    content: "\eb87";
  }
  &.stats-bars3:before {
    content: "\eb88";
  }
  &.stats-bars4:before {
    content: "\eb89";
  }
  &.stats-bars5:before {
    content: "\eb8a";
  }
  &.stats-bars6:before {
    content: "\eb8b";
  }
  &.chart:before {
    content: "\eb8c";
  }
  &.stats-growth:before {
    content: "\eb8d";
  }
  &.stats-decline:before {
    content: "\eb8e";
  }
  &.stats-growth2:before {
    content: "\eb8f";
  }
  &.stats-decline2:before {
    content: "\eb90";
  }
  &.stairs-up:before {
    content: "\eb91";
  }
  &.stairs-down:before {
    content: "\eb92";
  }
  &.stairs:before {
    content: "\eb93";
  }
  &.stairs2:before {
    content: "\eb94";
  }
  &.ladder:before {
    content: "\eb95";
  }
  &.rating:before {
    content: "\eb96";
  }
  &.rating2:before {
    content: "\eb97";
  }
  &.rating3:before {
    content: "\eb98";
  }
  &.cake:before {
    content: "\eb99";
  }
  &.podium:before {
    content: "\eb9a";
  }
  &.stars:before {
    content: "\eb9b";
  }
  &.medal-star:before {
    content: "\eb9c";
  }
  &.medal:before {
    content: "\eb9d";
  }
  &.medal2:before {
    content: "\eb9e";
  }
  &.medal-first:before {
    content: "\eb9f";
  }
  &.medal-second:before {
    content: "\eba0";
  }
  &.medal-third:before {
    content: "\eba1";
  }
  &.crown:before {
    content: "\eba2";
  }
  &.trophy:before {
    content: "\eba3";
  }
  &.trophy2:before {
    content: "\eba4";
  }
  &.trophy3:before {
    content: "\eba5";
  }
  &.diamond:before {
    content: "\eba6";
  }
  &.diamond2:before {
    content: "\eba7";
  }
  &.trophy4:before {
    content: "\eba8";
  }
  &.gift:before {
    content: "\eba9";
  }
  &.gift2:before {
    content: "\ebaa";
  }
  &.balloon:before {
    content: "\ebab";
  }
  &.pipe:before {
    content: "\ebac";
  }
  &.mustache:before {
    content: "\ebad";
  }
  &.mustache2:before {
    content: "\ebae";
  }
  &.glass:before {
    content: "\ebaf";
  }
  &.glass2:before {
    content: "\ebb0";
  }
  &.glass3:before {
    content: "\ebb1";
  }
  &.glass4:before {
    content: "\ebb2";
  }
  &.bottle:before {
    content: "\ebb3";
  }
  &.bottle2:before {
    content: "\ebb4";
  }
  &.bottle3:before {
    content: "\ebb5";
  }
  &.bottle4:before {
    content: "\ebb6";
  }
  &.mug:before {
    content: "\ebb7";
  }
  &.fork-knife:before {
    content: "\ebb8";
  }
  &.spoon-knife:before {
    content: "\ebb9";
  }
  &.cook:before {
    content: "\ebba";
  }
  &.plate:before {
    content: "\ebbb";
  }
  &.steak:before {
    content: "\ebbc";
  }
  &.fish:before {
    content: "\ebbd";
  }
  &.chicken:before {
    content: "\ebbe";
  }
  &.hamburger:before {
    content: "\ebbf";
  }
  &.pizza:before {
    content: "\ebc0";
  }
  &.cheese:before {
    content: "\ebc1";
  }
  &.bread:before {
    content: "\ebc2";
  }
  &.icecream:before {
    content: "\ebc3";
  }
  &.milk:before {
    content: "\ebc4";
  }
  &.cup:before {
    content: "\ebc5";
  }
  &.cup2:before {
    content: "\ebc6";
  }
  &.coffee-baen:before {
    content: "\ebc7";
  }
  &.coffee:before {
    content: "\ebc8";
  }
  &.teapot:before {
    content: "\ebc9";
  }
  &.leaf:before {
    content: "\ebca";
  }
  &.leaf2:before {
    content: "\ebcb";
  }
  &.apple:before {
    content: "\ebcc";
  }
  &.grapes:before {
    content: "\ebcd";
  }
  &.cherry:before {
    content: "\ebce";
  }
  &.tree:before {
    content: "\ebcf";
  }
  &.tree2:before {
    content: "\ebd0";
  }
  &.tree3:before {
    content: "\ebd1";
  }
  &.tree4:before {
    content: "\ebd2";
  }
  &.cactus:before {
    content: "\ebd3";
  }
  &.mountains:before {
    content: "\ebd4";
  }
  &.paw:before {
    content: "\ebd5";
  }
  &.footprint:before {
    content: "\ebd6";
  }
  &.tent:before {
    content: "\ebd7";
  }
  &.flower:before {
    content: "\ebd8";
  }
  &.flower2:before {
    content: "\ebd9";
  }
  &.rocket:before {
    content: "\ebda";
  }
  &.meter:before {
    content: "\ebdb";
  }
  &.meter2:before {
    content: "\ebdc";
  }
  &.meter-slow:before {
    content: "\ebdd";
  }
  &.meter-medium:before {
    content: "\ebde";
  }
  &.meter-fast:before {
    content: "\ebdf";
  }
  &.meter3:before {
    content: "\ebe0";
  }
  &.hammer2:before {
    content: "\ebe1";
  }
  &.balance:before {
    content: "\ebe2";
  }
  &.scale:before {
    content: "\ebe3";
  }
  &.bomb:before {
    content: "\ebe4";
  }
  &.fire:before {
    content: "\ebe5";
  }
  &.fire2:before {
    content: "\ebe6";
  }
  &.lab:before {
    content: "\ebe7";
  }
  &.atom:before {
    content: "\ebe8";
  }
  &.atom2:before {
    content: "\ebe9";
  }
  &.magnet:before {
    content: "\ebea";
  }
  &.magnet2:before {
    content: "\ebeb";
  }
  &.dumbbell:before {
    content: "\ebec";
  }
  &.skull:before {
    content: "\ebed";
  }
  &.skull2:before {
    content: "\ebee";
  }
  &.lamp:before {
    content: "\ebef";
  }
  &.lamp2:before {
    content: "\ebf0";
  }
  &.lamp3:before {
    content: "\ebf1";
  }
  &.spotlight:before {
    content: "\ebf2";
  }
  &.lamp4:before {
    content: "\ebf3";
  }
  &.lamp5:before {
    content: "\ebf4";
  }
  &.lamp6:before {
    content: "\ebf5";
  }
  &.lamp7:before {
    content: "\ebf6";
  }
  &.lamp8:before {
    content: "\ebf7";
  }
  &.broom:before {
    content: "\ebf8";
  }
  &.toiletpaper:before {
    content: "\ebf9";
  }
  &.bin:before {
    content: "\ebfa";
  }
  &.bin2:before {
    content: "\ebfb";
  }
  &.bin3:before {
    content: "\ebfc";
  }
  &.bin4:before {
    content: "\ebfd";
  }
  &.bin5:before {
    content: "\ebfe";
  }
  &.briefcase:before {
    content: "\ebff";
  }
  &.briefcase2:before {
    content: "\ec00";
  }
  &.briefcase3:before {
    content: "\ec01";
  }
  &.airplane:before {
    content: "\ec02";
  }
  &.airplane2:before {
    content: "\ec03";
  }
  &.airplane3:before {
    content: "\ec04";
  }
  &.airplane4:before {
    content: "\ec05";
  }
  &.paperplane:before {
    content: "\ec06";
  }
  &.car:before {
    content: "\ec07";
  }
  &.steering-wheel:before {
    content: "\ec08";
  }
  &.car2:before {
    content: "\ec09";
  }
  &.gas:before {
    content: "\ec0a";
  }
  &.bus:before {
    content: "\ec0b";
  }
  &.truck:before {
    content: "\ec0c";
  }
  &.bike:before {
    content: "\ec0d";
  }
  &.road:before {
    content: "\ec0e";
  }
  &.train:before {
    content: "\ec0f";
  }
  &.train2:before {
    content: "\ec10";
  }
  &.ship:before {
    content: "\ec11";
  }
  &.boat:before {
    content: "\ec12";
  }
  &.chopper:before {
    content: "\ec13";
  }
  &.chopper2:before {
    content: "\ec14";
  }
  &.cube:before {
    content: "\ec15";
  }
  &.cube2:before {
    content: "\ec16";
  }
  &.cube3:before {
    content: "\ec17";
  }
  &.cube4:before {
    content: "\ec18";
  }
  &.pyramid:before {
    content: "\ec19";
  }
  &.pyramid2:before {
    content: "\ec1a";
  }
  &.package:before {
    content: "\ec1b";
  }
  &.puzzle:before {
    content: "\ec1c";
  }
  &.puzzle2:before {
    content: "\ec1d";
  }
  &.puzzle3:before {
    content: "\ec1e";
  }
  &.puzzle4:before {
    content: "\ec1f";
  }
  &.glasses-3d:before {
    content: "\ec20";
  }
  &.glasses-3d2:before {
    content: "\ec21";
  }
  &.glasses:before {
    content: "\ec22";
  }
  &.glasses2:before {
    content: "\ec23";
  }
  &.brain:before {
    content: "\ec24";
  }
  &.accessibility:before {
    content: "\ec25";
  }
  &.accessibility2:before {
    content: "\ec26";
  }
  &.strategy:before {
    content: "\ec27";
  }
  &.target:before {
    content: "\ec28";
  }
  &.target2:before {
    content: "\ec29";
  }
  &.target3:before {
    content: "\ec2a";
  }
  &.ammo:before {
    content: "\ec2b";
  }
  &.gun:before {
    content: "\ec2c";
  }
  &.gun-forbidden:before {
    content: "\ec2d";
  }
  &.shield:before {
    content: "\ec2e";
  }
  &.shield-check:before {
    content: "\ec2f";
  }
  &.shield-notice:before {
    content: "\ec30";
  }
  &.shield2:before {
    content: "\ec31";
  }
  &.shield3:before {
    content: "\ec32";
  }
  &.shield4:before {
    content: "\ec33";
  }
  &.soccer:before {
    content: "\ec34";
  }
  &.football:before {
    content: "\ec35";
  }
  &.archery:before {
    content: "\ec36";
  }
  &.bow:before {
    content: "\ec37";
  }
  &.sword:before {
    content: "\ec38";
  }
  &.tennis:before {
    content: "\ec39";
  }
  &.pingpong:before {
    content: "\ec3a";
  }
  &.baseball:before {
    content: "\ec3b";
  }
  &.basketball:before {
    content: "\ec3c";
  }
  &.golf:before {
    content: "\ec3d";
  }
  &.golf2:before {
    content: "\ec3e";
  }
  &.hockey:before {
    content: "\ec3f";
  }
  &.racing:before {
    content: "\ec40";
  }
  &.finish:before {
    content: "\ec41";
  }
  &.eight-ball:before {
    content: "\ec42";
  }
  &.bowling:before {
    content: "\ec43";
  }
  &.bowling2:before {
    content: "\ec44";
  }
  &.power:before {
    content: "\ec45";
  }
  &.power2:before {
    content: "\ec46";
  }
  &.power3:before {
    content: "\ec47";
  }
  &.switch:before {
    content: "\ec48";
  }
  &.switch2:before {
    content: "\ec49";
  }
  &.power-cord:before {
    content: "\ec4a";
  }
  &.power-cord2:before {
    content: "\ec4b";
  }
  &.outlet:before {
    content: "\ec4c";
  }
  &.clipboard:before {
    content: "\ec4d";
  }
  &.clipboard2:before {
    content: "\ec4e";
  }
  &.clipboard3:before {
    content: "\ec4f";
  }
  &.clipboard4:before {
    content: "\ec50";
  }
  &.clipboard5:before {
    content: "\ec51";
  }
  &.clipboard6:before {
    content: "\ec52";
  }
  &.playlist:before {
    content: "\ec53";
  }
  &.playlist-add:before {
    content: "\ec54";
  }
  &.list-numbered:before {
    content: "\ec55";
  }
  &.list:before {
    content: "\ec56";
  }
  &.list2:before {
    content: "\ec57";
  }
  &.more:before {
    content: "\ec58";
  }
  &.more2:before {
    content: "\ec59";
  }
  &.grid:before {
    content: "\ec5a";
  }
  &.grid2:before {
    content: "\ec5b";
  }
  &.grid3:before {
    content: "\ec5c";
  }
  &.grid4:before {
    content: "\ec5d";
  }
  &.grid5:before {
    content: "\ec5e";
  }
  &.grid6:before {
    content: "\ec5f";
  }
  &.grid7:before {
    content: "\ec60";
  }
  &.tree5:before {
    content: "\ec61";
  }
  &.tree6:before {
    content: "\ec62";
  }
  &.tree7:before {
    content: "\ec63";
  }
  &.lan:before {
    content: "\ec64";
  }
  &.lan2:before {
    content: "\ec65";
  }
  &.lan3:before {
    content: "\ec66";
  }
  &.menu:before {
    content: "\ec67";
  }
  &.circle-small:before {
    content: "\ec68";
  }
  &.menu2:before {
    content: "\ec69";
  }
  &.menu3:before {
    content: "\ec6a";
  }
  &.menu4:before {
    content: "\ec6b";
  }
  &.menu5:before {
    content: "\ec6c";
  }
  &.menu6:before {
    content: "\ec6d";
  }
  &.menu7:before {
    content: "\ec6e";
  }
  &.menu8:before {
    content: "\ec6f";
  }
  &.menu9:before {
    content: "\ec70";
  }
  &.menu10:before {
    content: "\ec71";
  }
  &.cloud:before {
    content: "\ec72";
  }
  &.cloud-download:before {
    content: "\ec73";
  }
  &.cloud-upload:before {
    content: "\ec74";
  }
  &.cloud-check:before {
    content: "\ec75";
  }
  &.cloud2:before {
    content: "\ec76";
  }
  &.cloud-download2:before {
    content: "\ec77";
  }
  &.cloud-upload2:before {
    content: "\ec78";
  }
  &.cloud-check2:before {
    content: "\ec79";
  }
  &.download2:before {
    content: "\ec7a";
  }
  &.download3:before {
    content: "\ec7b";
  }
  &.upload2:before {
    content: "\ec7c";
  }
  &.upload3:before {
    content: "\ec7d";
  }
  &.import:before {
    content: "\ec7e";
  }
  &.import2:before {
    content: "\ec7f";
  }
  &.download4:before {
    content: "\ec80";
  }
  &.upload4:before {
    content: "\ec81";
  }
  &.download5:before {
    content: "\ec82";
  }
  &.upload5:before {
    content: "\ec83";
  }
  &.download6:before {
    content: "\ec84";
  }
  &.upload6:before {
    content: "\ec85";
  }
  &.download7:before {
    content: "\ec86";
  }
  &.upload7:before {
    content: "\ec87";
  }
  &.download8:before {
    content: "\ec88";
  }
  &.upload8:before {
    content: "\ec89";
  }
  &.download9:before {
    content: "\ec8a";
  }
  &.upload9:before {
    content: "\ec8b";
  }
  &.download10:before {
    content: "\ec8c";
  }
  &.upload10:before {
    content: "\ec8d";
  }
  &.sphere:before {
    content: "\ec8e";
  }
  &.sphere2:before {
    content: "\ec8f";
  }
  &.sphere3:before {
    content: "\ec90";
  }
  &.planet:before {
    content: "\ec91";
  }
  &.planet2:before {
    content: "\ec92";
  }
  &.earth:before {
    content: "\ec93";
  }
  &.earth2:before {
    content: "\ec94";
  }
  &.earth3:before {
    content: "\ec95";
  }
  &.link:before {
    content: "\ec96";
  }
  &.unlink:before {
    content: "\ec97";
  }
  &.link2:before {
    content: "\ec98";
  }
  &.unlink2:before {
    content: "\ec99";
  }
  &.link3:before {
    content: "\ec9a";
  }
  &.unlink3:before {
    content: "\ec9b";
  }
  &.link4:before {
    content: "\ec9c";
  }
  &.unlink4:before {
    content: "\ec9d";
  }
  &.link5:before {
    content: "\ec9e";
  }
  &.unlink5:before {
    content: "\ec9f";
  }
  &.anchor:before {
    content: "\eca0";
  }
  &.flag:before {
    content: "\eca1";
  }
  &.flag2:before {
    content: "\eca2";
  }
  &.flag3:before {
    content: "\eca3";
  }
  &.flag4:before {
    content: "\eca4";
  }
  &.flag5:before {
    content: "\eca5";
  }
  &.flag6:before {
    content: "\eca6";
  }
  &.flag7:before {
    content: "\eca7";
  }
  &.flag8:before {
    content: "\eca8";
  }
  &.attachment:before {
    content: "\eca9";
  }
  &.attachment2:before {
    content: "\ecaa";
  }
  &.eye:before {
    content: "\ecab";
  }
  &.eye-plus:before {
    content: "\ecac";
  }
  &.eye-minus:before {
    content: "\ecad";
  }
  &.eye-blocked:before {
    content: "\ecae";
  }
  &.eye2:before {
    content: "\ecaf";
  }
  &.eye-blocked2:before {
    content: "\ecb0";
  }
  &.eye3:before {
    content: "\ecb1";
  }
  &.eye-blocked3:before {
    content: "\ecb2";
  }
  &.eye4:before {
    content: "\ecb3";
  }
  &.bookmark2:before {
    content: "\ecb4";
  }
  &.bookmark3:before {
    content: "\ecb5";
  }
  &.bookmarks:before {
    content: "\ecb6";
  }
  &.bookmark4:before {
    content: "\ecb7";
  }
  &.spotlight2:before {
    content: "\ecb8";
  }
  &.starburst:before {
    content: "\ecb9";
  }
  &.snowflake:before {
    content: "\ecba";
  }
  &.temperature:before {
    content: "\ecbb";
  }
  &.temperature2:before {
    content: "\ecbc";
  }
  &.weather-lightning:before {
    content: "\ecbd";
  }
  &.weather-lightning2:before {
    content: "\ecbe";
  }
  &.weather-rain:before {
    content: "\ecbf";
  }
  &.weather-rain2:before {
    content: "\ecc0";
  }
  &.weather-snow:before {
    content: "\ecc1";
  }
  &.weather-snow2:before {
    content: "\ecc2";
  }
  &.weather-cloud-wind:before {
    content: "\ecc3";
  }
  &.weather-cloud-wind2:before {
    content: "\ecc4";
  }
  &.weather-cloud-sun:before {
    content: "\ecc5";
  }
  &.weather-cloud-sun2:before {
    content: "\ecc6";
  }
  &.weather-cloudy:before {
    content: "\ecc7";
  }
  &.weather-cloudy2:before {
    content: "\ecc8";
  }
  &.weather-sun-wind:before {
    content: "\ecc9";
  }
  &.weather-sun-wind2:before {
    content: "\ecca";
  }
  &.sun:before {
    content: "\eccb";
  }
  &.sun2:before {
    content: "\eccc";
  }
  &.moon:before {
    content: "\eccd";
  }
  &.day-night:before {
    content: "\ecce";
  }
  &.day-night2:before {
    content: "\eccf";
  }
  &.weather-windy:before {
    content: "\ecd0";
  }
  &.fan:before {
    content: "\ecd1";
  }
  &.umbrella:before {
    content: "\ecd2";
  }
  &.sun3:before {
    content: "\ecd3";
  }
  &.contrast:before {
    content: "\ecd4";
  }
  &.brightness-contrast:before {
    content: "\ecd5";
  }
  &.brightness-high:before {
    content: "\ecd6";
  }
  &.brightness-medium:before {
    content: "\ecd7";
  }
  &.brightness-low:before {
    content: "\ecd8";
  }
  &.bed:before {
    content: "\ecd9";
  }
  &.bed2:before {
    content: "\ecda";
  }
  &.furniture:before {
    content: "\ecdb";
  }
  &.chair:before {
    content: "\ecdc";
  }
  &.star-empty:before {
    content: "\ecdd";
  }
  &.star-empty2:before {
    content: "\ecde";
  }
  &.star-full:before {
    content: "\ecdf";
  }
  &.star-empty3:before {
    content: "\ece0";
  }
  &.star-half:before {
    content: "\ece1";
  }
  &.star-full2:before {
    content: "\ece2";
  }
  &.star:before {
    content: "\ece3";
  }
  &.heart:before {
    content: "\ece4";
  }
  &.heart2:before {
    content: "\ece5";
  }
  &.heart3:before {
    content: "\ece6";
  }
  &.heart4:before {
    content: "\ece7";
  }
  &.heart-broken:before {
    content: "\ece8";
  }
  &.heart5:before {
    content: "\ece9";
  }
  &.heart6:before {
    content: "\ecea";
  }
  &.heart-broken2:before {
    content: "\eceb";
  }
  &.heart7:before {
    content: "\ecec";
  }
  &.heart8:before {
    content: "\eced";
  }
  &.heart-broken3:before {
    content: "\ecee";
  }
  &.lips:before {
    content: "\ecef";
  }
  &.lips2:before {
    content: "\ecf0";
  }
  &.thumbs-up:before {
    content: "\ecf1";
  }
  &.thumbs-up2:before {
    content: "\ecf2";
  }
  &.thumbs-down:before {
    content: "\ecf3";
  }
  &.thumbs-down2:before {
    content: "\ecf4";
  }
  &.thumbs-up3:before {
    content: "\ecf5";
  }
  &.thumbs-down3:before {
    content: "\ecf6";
  }
  &.height:before {
    content: "\ecf7";
  }
  &.man:before {
    content: "\ecf8";
  }
  &.woman:before {
    content: "\ecf9";
  }
  &.man-woman:before {
    content: "\ecfa";
  }
  &.male:before {
    content: "\ecfb";
  }
  &.female:before {
    content: "\ecfc";
  }
  &.peace:before {
    content: "\ecfd";
  }
  &.yin-yang:before {
    content: "\ecfe";
  }
  &.happy:before {
    content: "\ecff";
  }
  &.happy2:before {
    content: "\ed00";
  }
  &.smile:before {
    content: "\ed01";
  }
  &.smile2:before {
    content: "\ed02";
  }
  &.tongue:before {
    content: "\ed03";
  }
  &.tongue2:before {
    content: "\ed04";
  }
  &.sad:before {
    content: "\ed05";
  }
  &.sad2:before {
    content: "\ed06";
  }
  &.wink:before {
    content: "\ed07";
  }
  &.wink2:before {
    content: "\ed08";
  }
  &.grin:before {
    content: "\ed09";
  }
  &.grin2:before {
    content: "\ed0a";
  }
  &.cool:before {
    content: "\ed0b";
  }
  &.cool2:before {
    content: "\ed0c";
  }
  &.angry:before {
    content: "\ed0d";
  }
  &.angry2:before {
    content: "\ed0e";
  }
  &.evil:before {
    content: "\ed0f";
  }
  &.evil2:before {
    content: "\ed10";
  }
  &.shocked:before {
    content: "\ed11";
  }
  &.shocked2:before {
    content: "\ed12";
  }
  &.baffled:before {
    content: "\ed13";
  }
  &.baffled2:before {
    content: "\ed14";
  }
  &.confused:before {
    content: "\ed15";
  }
  &.confused2:before {
    content: "\ed16";
  }
  &.neutral:before {
    content: "\ed17";
  }
  &.neutral2:before {
    content: "\ed18";
  }
  &.hipster:before {
    content: "\ed19";
  }
  &.hipster2:before {
    content: "\ed1a";
  }
  &.wondering:before {
    content: "\ed1b";
  }
  &.wondering2:before {
    content: "\ed1c";
  }
  &.sleepy:before {
    content: "\ed1d";
  }
  &.sleepy2:before {
    content: "\ed1e";
  }
  &.frustrated:before {
    content: "\ed1f";
  }
  &.frustrated2:before {
    content: "\ed20";
  }
  &.crying:before {
    content: "\ed21";
  }
  &.crying2:before {
    content: "\ed22";
  }
  &.cursor:before {
    content: "\ed23";
  }
  &.cursor2:before {
    content: "\ed24";
  }
  &.lasso:before {
    content: "\ed25";
  }
  &.lasso2:before {
    content: "\ed26";
  }
  &.select:before {
    content: "\ed27";
  }
  &.select2:before {
    content: "\ed28";
  }
  &.point-up:before {
    content: "\ed29";
  }
  &.point-right:before {
    content: "\ed2a";
  }
  &.point-down:before {
    content: "\ed2b";
  }
  &.point-left:before {
    content: "\ed2c";
  }
  &.pointer:before {
    content: "\ed2d";
  }
  &.reminder:before {
    content: "\ed2e";
  }
  &.drag-left-right:before {
    content: "\ed2f";
  }
  &.drag-left:before {
    content: "\ed30";
  }
  &.drag-right:before {
    content: "\ed31";
  }
  &.touch:before {
    content: "\ed32";
  }
  &.multitouch:before {
    content: "\ed33";
  }
  &.touch-zoom:before {
    content: "\ed34";
  }
  &.touch-pinch:before {
    content: "\ed35";
  }
  &.hand:before {
    content: "\ed36";
  }
  &.grab:before {
    content: "\ed37";
  }
  &.stack-empty:before {
    content: "\ed38";
  }
  &.stack-plus:before {
    content: "\ed39";
  }
  &.stack-minus:before {
    content: "\ed3a";
  }
  &.stack-star:before {
    content: "\ed3b";
  }
  &.stack-picture:before {
    content: "\ed3c";
  }
  &.stack-down:before {
    content: "\ed3d";
  }
  &.stack-up:before {
    content: "\ed3e";
  }
  &.stack-cancel:before {
    content: "\ed3f";
  }
  &.stack-check:before {
    content: "\ed40";
  }
  &.stack-text:before {
    content: "\ed41";
  }
  &.stack-clubs:before {
    content: "\ed42";
  }
  &.stack-spades:before {
    content: "\ed43";
  }
  &.stack-hearts:before {
    content: "\ed44";
  }
  &.stack-diamonds:before {
    content: "\ed45";
  }
  &.stack-user:before {
    content: "\ed46";
  }
  &.stack4:before {
    content: "\ed47";
  }
  &.stack-music:before {
    content: "\ed48";
  }
  &.stack-play:before {
    content: "\ed49";
  }
  &.move:before {
    content: "\ed4a";
  }
  &.dots:before {
    content: "\ed4b";
  }
  &.warning:before {
    content: "\ed4c";
  }
  &.warning2:before {
    content: "\ed4d";
  }
  &.warning3:before {
    content: "\e602";
  }
  &.notification:before {
    content: "\ed4e";
  }
  &.notification2:before {
    content: "\ed4f";
  }
  &.question:before {
    content: "\ed50";
  }
  &.question2:before {
    content: "\ed51";
  }
  &.question3:before {
    content: "\ed52";
  }
  &.question4:before {
    content: "\ed53";
  }
  &.question5:before {
    content: "\ed54";
  }
  &.question6:before {
    content: "\ed55";
  }
  &.plus:before {
    content: "\ed56";
  }
  &.minus:before {
    content: "\ed57";
  }
  &.plus2:before {
    content: "\ed58";
  }
  &.minus2:before {
    content: "\ed59";
  }
  &.plus3:before {
    content: "\ed5a";
  }
  &.minus3:before {
    content: "\ed5b";
  }
  &.plus-circle:before {
    content: "\ed5c";
  }
  &.minus-circle:before {
    content: "\ed5d";
  }
  &.plus-circle2:before {
    content: "\ed5e";
  }
  &.minus-circle2:before {
    content: "\ed5f";
  }
  &.info:before {
    content: "\ed60";
  }
  &.info2:before {
    content: "\ed61";
  }
  &.cancel-circle:before {
    content: "\ed62";
  }
  &.cancel-circle2:before {
    content: "\ed63";
  }
  &.blocked:before {
    content: "\ed64";
  }
  &.cancel-square:before {
    content: "\ed65";
  }
  &.cancel-square2:before {
    content: "\ed66";
  }
  &.cancel:before {
    content: "\ed67";
  }
  &.spam:before {
    content: "\ed68";
  }
  &.cross:before {
    content: "\ed69";
  }
  &.cross2:before {
    content: "\ed6a";
  }
  &.cross3:before {
    content: "\ed6b";
  }
  &.checkmark:before {
    content: "\ed6c";
  }
  &.checkmark2:before {
    content: "\ed6d";
  }
  &.checkmark3:before {
    content: "\ed6e";
  }
  &.checkmark4:before {
    content: "\ed6f";
  }
  &.checkmark5:before {
    content: "\ed70";
  }
  &.spell-check:before {
    content: "\ed71";
  }
  &.spell-check2:before {
    content: "\ed72";
  }
  &.enter:before {
    content: "\ed73";
  }
  &.exit:before {
    content: "\ed74";
  }
  &.enter2:before {
    content: "\ed75";
  }
  &.exit2:before {
    content: "\ed76";
  }
  &.enter3:before {
    content: "\ed77";
  }
  &.exit3:before {
    content: "\ed78";
  }
  &.wall:before {
    content: "\ed79";
  }
  &.fence:before {
    content: "\ed7a";
  }
  &.play3:before {
    content: "\ed7b";
  }
  &.pause:before {
    content: "\ed7c";
  }
  &.stop:before {
    content: "\ed7d";
  }
  &.previous:before {
    content: "\ed7e";
  }
  &.next:before {
    content: "\ed7f";
  }
  &.backward:before {
    content: "\ed80";
  }
  &.forward2:before {
    content: "\ed81";
  }
  &.play4:before {
    content: "\ed82";
  }
  &.pause2:before {
    content: "\ed83";
  }
  &.stop2:before {
    content: "\ed84";
  }
  &.backward2:before {
    content: "\ed85";
  }
  &.forward3:before {
    content: "\ed86";
  }
  &.first:before {
    content: "\ed87";
  }
  &.last:before {
    content: "\ed88";
  }
  &.previous2:before {
    content: "\ed89";
  }
  &.next2:before {
    content: "\ed8a";
  }
  &.eject:before {
    content: "\ed8b";
  }
  &.volume-high:before {
    content: "\ed8c";
  }
  &.volume-medium:before {
    content: "\ed8d";
  }
  &.volume-low:before {
    content: "\ed8e";
  }
  &.volume-mute:before {
    content: "\ed8f";
  }
  &.speaker-left:before {
    content: "\ed90";
  }
  &.speaker-right:before {
    content: "\ed91";
  }
  &.volume-mute2:before {
    content: "\ed92";
  }
  &.volume-increase:before {
    content: "\ed93";
  }
  &.volume-decrease:before {
    content: "\ed94";
  }
  &.volume-high2:before {
    content: "\ed95";
  }
  &.volume-medium2:before {
    content: "\ed96";
  }
  &.volume-medium3:before {
    content: "\ed97";
  }
  &.volume-low2:before {
    content: "\ed98";
  }
  &.volume-mute3:before {
    content: "\ed99";
  }
  &.volume-increase2:before {
    content: "\ed9a";
  }
  &.volume-decrease2:before {
    content: "\ed9b";
  }
  &.volume-decrease3:before {
    content: "\ed9c";
  }
  &.volume-5:before {
    content: "\ed9d";
  }
  &.volume-4:before {
    content: "\ed9e";
  }
  &.volume-3:before {
    content: "\ed9f";
  }
  &.volume-2:before {
    content: "\eda0";
  }
  &.volume-1:before {
    content: "\eda1";
  }
  &.volume-0:before {
    content: "\eda2";
  }
  &.volume-mute4:before {
    content: "\eda3";
  }
  &.volume-mute5:before {
    content: "\eda4";
  }
  &.loop:before {
    content: "\eda5";
  }
  &.loop2:before {
    content: "\eda6";
  }
  &.loop3:before {
    content: "\eda7";
  }
  &.infinite-square:before {
    content: "\eda8";
  }
  &.infinite:before {
    content: "\eda9";
  }
  &.infinite2:before {
    content: "\edaa";
  }
  &.loop4:before {
    content: "\edab";
  }
  &.shuffle:before {
    content: "\edac";
  }
  &.shuffle2:before {
    content: "\edad";
  }
  &.wave:before {
    content: "\edae";
  }
  &.wave2:before {
    content: "\edaf";
  }
  &.split:before {
    content: "\edb0";
  }
  &.merge:before {
    content: "\edb1";
  }
  &.first2:before {
    content: "\edb2";
  }
  &.last2:before {
    content: "\edb3";
  }
  &.arrow-up:before {
    content: "\edb4";
  }
  &.arrow-up2:before {
    content: "\edb5";
  }
  &.arrow-up3:before {
    content: "\edb6";
  }
  &.arrow-up4:before {
    content: "\edb7";
  }
  &.arrow-right:before {
    content: "\edb8";
  }
  &.arrow-right2:before {
    content: "\edb9";
  }
  &.arrow-right3:before {
    content: "\edba";
  }
  &.arrow-right4:before {
    content: "\edbb";
  }
  &.arrow-down:before {
    content: "\edbc";
  }
  &.arrow-down2:before {
    content: "\edbd";
  }
  &.arrow-down3:before {
    content: "\edbe";
  }
  &.arrow-down4:before {
    content: "\edbf";
  }
  &.arrow-left:before {
    content: "\edc0";
  }
  &.arrow-left2:before {
    content: "\edc1";
  }
  &.arrow-left3:before {
    content: "\edc2";
  }
  &.arrow-left4:before {
    content: "\edc3";
  }
  &.arrow-up5:before {
    content: "\edc4";
  }
  &.arrow-right5:before {
    content: "\edc5";
  }
  &.arrow-down5:before {
    content: "\edc6";
  }
  &.arrow-left5:before {
    content: "\edc7";
  }
  &.arrow-up-left:before {
    content: "\edc8";
  }
  &.arrow-up6:before {
    content: "\edc9";
  }
  &.arrow-up-right:before {
    content: "\edca";
  }
  &.arrow-right6:before {
    content: "\edcb";
  }
  &.arrow-down-right:before {
    content: "\edcc";
  }
  &.arrow-down6:before {
    content: "\edcd";
  }
  &.arrow-down-left:before {
    content: "\edce";
  }
  &.arrow-left6:before {
    content: "\edcf";
  }
  &.arrow-up-left2:before {
    content: "\edd0";
  }
  &.arrow-up7:before {
    content: "\edd1";
  }
  &.arrow-up-right2:before {
    content: "\edd2";
  }
  &.arrow-right7:before {
    content: "\edd3";
  }
  &.arrow-down-right2:before {
    content: "\edd4";
  }
  &.arrow-down7:before {
    content: "\edd5";
  }
  &.arrow-down-left2:before {
    content: "\edd6";
  }
  &.arrow-left7:before {
    content: "\edd7";
  }
  &.arrow-up-left3:before {
    content: "\edd8";
  }
  &.arrow-up8:before {
    content: "\edd9";
  }
  &.arrow-up-right3:before {
    content: "\edda";
  }
  &.arrow-right8:before {
    content: "\eddb";
  }
  &.arrow-down-right3:before {
    content: "\eddc";
  }
  &.arrow-down8:before {
    content: "\eddd";
  }
  &.arrow-down-left3:before {
    content: "\edde";
  }
  &.arrow-left8:before {
    content: "\eddf";
  }
  &.circle-up:before {
    content: "\ede0";
  }
  &.circle-right:before {
    content: "\ede1";
  }
  &.circle-down:before {
    content: "\ede2";
  }
  &.circle-left:before {
    content: "\ede3";
  }
  &.circle-up2:before {
    content: "\ede4";
  }
  &.circle-right2:before {
    content: "\ede5";
  }
  &.circle-down2:before {
    content: "\ede6";
  }
  &.circle-left2:before {
    content: "\ede7";
  }
  &.circle-up-left:before {
    content: "\ede8";
  }
  &.circle-up3:before {
    content: "\ede9";
  }
  &.circle-up-right:before {
    content: "\edea";
  }
  &.circle-right3:before {
    content: "\edeb";
  }
  &.circle-down-right:before {
    content: "\edec";
  }
  &.circle-down3:before {
    content: "\eded";
  }
  &.circle-down-left:before {
    content: "\edee";
  }
  &.circle-left3:before {
    content: "\edef";
  }
  &.circle-up-left2:before {
    content: "\edf0";
  }
  &.circle-up4:before {
    content: "\edf1";
  }
  &.circle-up-right2:before {
    content: "\edf2";
  }
  &.circle-right4:before {
    content: "\edf3";
  }
  &.circle-down-right2:before {
    content: "\edf4";
  }
  &.circle-down4:before {
    content: "\edf5";
  }
  &.circle-down-left2:before {
    content: "\edf6";
  }
  &.circle-left4:before {
    content: "\edf7";
  }
  &.arrow-resize:before {
    content: "\edf8";
  }
  &.arrow-resize2:before {
    content: "\edf9";
  }
  &.arrow-resize3:before {
    content: "\edfa";
  }
  &.arrow-resize4:before {
    content: "\edfb";
  }
  &.arrow-resize5:before {
    content: "\edfc";
  }
  &.arrow-resize6:before {
    content: "\edfd";
  }
  &.arrow-resize7:before {
    content: "\edfe";
  }
  &.arrow-resize8:before {
    content: "\edff";
  }
  &.square-up-left:before {
    content: "\ee00";
  }
  &.square-up:before {
    content: "\ee01";
  }
  &.square-up-right:before {
    content: "\ee02";
  }
  &.square-right:before {
    content: "\ee03";
  }
  &.square-down-right:before {
    content: "\ee04";
  }
  &.square-down:before {
    content: "\ee05";
  }
  &.square-down-left:before {
    content: "\ee06";
  }
  &.square-left:before {
    content: "\ee07";
  }
  &.arrow-up9:before {
    content: "\ee08";
  }
  &.arrow-right9:before {
    content: "\ee09";
  }
  &.arrow-down9:before {
    content: "\ee0a";
  }
  &.arrow-left9:before {
    content: "\ee0b";
  }
  &.arrow-up10:before {
    content: "\ee0c";
  }
  &.arrow-right10:before {
    content: "\ee0d";
  }
  &.arrow-down10:before {
    content: "\ee0e";
  }
  &.arrow-left10:before {
    content: "\ee0f";
  }
  &.arrow-up-left4:before {
    content: "\ee10";
  }
  &.arrow-up11:before {
    content: "\ee11";
  }
  &.arrow-up-right4:before {
    content: "\ee12";
  }
  &.arrow-right11:before {
    content: "\ee13";
  }
  &.arrow-down-right4:before {
    content: "\ee14";
  }
  &.arrow-down11:before {
    content: "\ee15";
  }
  &.arrow-down-left4:before {
    content: "\ee16";
  }
  &.arrow-left11:before {
    content: "\ee17";
  }
  &.arrow-up-left5:before {
    content: "\ee18";
  }
  &.arrow-up12:before {
    content: "\ee19";
  }
  &.arrow-up-right5:before {
    content: "\ee1a";
  }
  &.arrow-right12:before {
    content: "\ee1b";
  }
  &.arrow-down-right5:before {
    content: "\ee1c";
  }
  &.arrow-down12:before {
    content: "\ee1d";
  }
  &.arrow-down-left5:before {
    content: "\ee1e";
  }
  &.arrow-left12:before {
    content: "\ee1f";
  }
  &.arrow-up13:before {
    content: "\ee20";
  }
  &.arrow-right13:before {
    content: "\ee21";
  }
  &.arrow-down13:before {
    content: "\ee22";
  }
  &.arrow-left13:before {
    content: "\ee23";
  }
  &.arrow-up14:before {
    content: "\ee24";
  }
  &.arrow-right14:before {
    content: "\ee25";
  }
  &.arrow-down14:before {
    content: "\ee26";
  }
  &.arrow-left14:before {
    content: "\ee27";
  }
  &.circle-up5:before {
    content: "\ee28";
  }
  &.circle-right5:before {
    content: "\ee29";
  }
  &.circle-down5:before {
    content: "\ee2a";
  }
  &.circle-left5:before {
    content: "\ee2b";
  }
  &.circle-up6:before {
    content: "\ee2c";
  }
  &.circle-right6:before {
    content: "\ee2d";
  }
  &.circle-down6:before {
    content: "\ee2e";
  }
  &.circle-left6:before {
    content: "\ee2f";
  }
  &.arrow-up15:before {
    content: "\ee30";
  }
  &.arrow-right15:before {
    content: "\ee31";
  }
  &.arrow-down15:before {
    content: "\ee32";
  }
  &.arrow-left15:before {
    content: "\ee33";
  }
  &.arrow-up16:before {
    content: "\ee34";
  }
  &.arrow-right16:before {
    content: "\ee35";
  }
  &.arrow-down16:before {
    content: "\ee36";
  }
  &.arrow-left16:before {
    content: "\ee37";
  }
  &.menu-open:before {
    content: "\ee38";
  }
  &.menu-open2:before {
    content: "\ee39";
  }
  &.menu-close:before {
    content: "\ee3a";
  }
  &.menu-close2:before {
    content: "\ee3b";
  }
  &.enter4:before {
    content: "\ee3c";
  }
  &.enter5:before {
    content: "\ee3d";
  }
  &.esc:before {
    content: "\ee3e";
  }
  &.enter6:before {
    content: "\ee3f";
  }
  &.backspace:before {
    content: "\ee40";
  }
  &.backspace2:before {
    content: "\ee41";
  }
  &.tab:before {
    content: "\ee42";
  }
  &.transmission:before {
    content: "\ee43";
  }
  &.transmission2:before {
    content: "\ee44";
  }
  &.sort:before {
    content: "\ee45";
  }
  &.sort2:before {
    content: "\ee46";
  }
  &.move-up:before {
    content: "\ee47";
  }
  &.move-down:before {
    content: "\ee48";
  }
  &.sort-alpha-asc:before {
    content: "\ee49";
  }
  &.sort-alpha-desc:before {
    content: "\ee4a";
  }
  &.sort-numeric-asc:before {
    content: "\ee4b";
  }
  &.sort-numberic-desc:before {
    content: "\ee4c";
  }
  &.sort-amount-asc:before {
    content: "\ee4d";
  }
  &.sort-amount-desc:before {
    content: "\ee4e";
  }
  &.sort-time-asc:before {
    content: "\ee4f";
  }
  &.sort-time-desc:before {
    content: "\ee50";
  }
  &.battery-6:before {
    content: "\ee51";
  }
  &.battery-5:before {
    content: "\ee52";
  }
  &.battery-4:before {
    content: "\ee53";
  }
  &.battery-3:before {
    content: "\ee54";
  }
  &.battery-2:before {
    content: "\ee55";
  }
  &.battery-1:before {
    content: "\ee56";
  }
  &.battery-0:before {
    content: "\ee57";
  }
  &.battery-charging:before {
    content: "\ee58";
  }
  &.key-keyboard:before {
    content: "\ee59";
  }
  &.key-A:before {
    content: "\ee5a";
  }
  &.key-up:before {
    content: "\ee5b";
  }
  &.key-right:before {
    content: "\ee5c";
  }
  &.key-down:before {
    content: "\ee5d";
  }
  &.key-left:before {
    content: "\ee5e";
  }
  &.command:before {
    content: "\ee5f";
  }
  &.shift:before {
    content: "\ee60";
  }
  &.ctrl:before {
    content: "\ee61";
  }
  &.opt:before {
    content: "\ee62";
  }
  &.checkbox-checked:before {
    content: "\ee63";
  }
  &.checkbox-unchecked:before {
    content: "\ee64";
  }
  &.checkbox-partial:before {
    content: "\ee65";
  }
  &.square:before {
    content: "\ee66";
  }
  &.triangle:before {
    content: "\ee67";
  }
  &.triangle2:before {
    content: "\ee68";
  }
  &.diamond3:before {
    content: "\ee69";
  }
  &.diamond4:before {
    content: "\ee6a";
  }
  &.checkbox-checked2:before {
    content: "\ee6b";
  }
  &.checkbox-unchecked2:before {
    content: "\ee6c";
  }
  &.checkbox-partial2:before {
    content: "\ee6d";
  }
  &.radio-checked:before {
    content: "\ee6e";
  }
  &.radio-checked2:before {
    content: "\ee6f";
  }
  &.radio-unchecked:before {
    content: "\ee70";
  }
  &.toggle-on:before {
    content: "\ee71";
  }
  &.toggle-off:before {
    content: "\ee72";
  }
  &.checkmark-circle:before {
    content: "\ee73";
  }
  &.circle:before {
    content: "\ee74";
  }
  &.circle2:before {
    content: "\ee75";
  }
  &.circles:before {
    content: "\ee76";
  }
  &.circles2:before {
    content: "\ee77";
  }
  &.crop:before {
    content: "\ee78";
  }
  &.crop2:before {
    content: "\ee79";
  }
  &.make-group:before {
    content: "\ee7a";
  }
  &.ungroup:before {
    content: "\ee7b";
  }
  &.vector:before {
    content: "\ee7c";
  }
  &.vector2:before {
    content: "\ee7d";
  }
  &.rulers:before {
    content: "\ee7e";
  }
  &.ruler:before {
    content: "\ee7f";
  }
  &.pencil-ruler:before {
    content: "\ee80";
  }
  &.scissors:before {
    content: "\ee81";
  }
  &.scissors2:before {
    content: "\ee82";
  }
  &.scissors3:before {
    content: "\ee83";
  }
  &.haircut:before {
    content: "\ee84";
  }
  &.comb:before {
    content: "\ee85";
  }
  &.filter:before {
    content: "\ee86";
  }
  &.filter2:before {
    content: "\ee87";
  }
  &.filter3:before {
    content: "\ee88";
  }
  &.filter4:before {
    content: "\ee89";
  }
  &.font:before {
    content: "\ee8a";
  }
  &.ampersand:before {
    content: "\ee8b";
  }
  &.ligature:before {
    content: "\ee8c";
  }
  &.ligature2:before {
    content: "\ee8d";
  }
  &.font-size:before {
    content: "\ee8e";
  }
  &.typography:before {
    content: "\ee8f";
  }
  &.text-height:before {
    content: "\ee90";
  }
  &.text-width:before {
    content: "\ee91";
  }
  &.height2:before {
    content: "\ee92";
  }
  &.width:before {
    content: "\ee93";
  }
  &.bold:before {
    content: "\ee94";
  }
  &.underline:before {
    content: "\ee95";
  }
  &.italic:before {
    content: "\ee96";
  }
  &.strikethrough:before {
    content: "\ee97";
  }
  &.strikethrough2:before {
    content: "\ee98";
  }
  &.font-size2:before {
    content: "\ee99";
  }
  &.bold2:before {
    content: "\ee9a";
  }
  &.underline2:before {
    content: "\ee9b";
  }
  &.italic2:before {
    content: "\ee9c";
  }
  &.strikethrough3:before {
    content: "\ee9d";
  }
  &.omega:before {
    content: "\ee9e";
  }
  &.sigma:before {
    content: "\ee9f";
  }
  &.nbsp:before {
    content: "\eea0";
  }
  &.page-break:before {
    content: "\eea1";
  }
  &.page-break2:before {
    content: "\eea2";
  }
  &.superscript:before {
    content: "\eea3";
  }
  &.subscript:before {
    content: "\eea4";
  }
  &.superscript2:before {
    content: "\eea5";
  }
  &.subscript2:before {
    content: "\eea6";
  }
  &.text-color:before {
    content: "\eea7";
  }
  &.highlight:before {
    content: "\eea8";
  }
  &.pagebreak:before {
    content: "\eea9";
  }
  &.clear-formatting:before {
    content: "\eeaa";
  }
  &.table:before {
    content: "\eeab";
  }
  &.table2:before {
    content: "\eeac";
  }
  &.insert-template:before {
    content: "\eead";
  }
  &.pilcrow:before {
    content: "\eeae";
  }
  &.ltr:before {
    content: "\eeaf";
  }
  &.rtl:before {
    content: "\eeb0";
  }
  &.ltr2:before {
    content: "\eeb1";
  }
  &.rtl2:before {
    content: "\eeb2";
  }
  &.section:before {
    content: "\eeb3";
  }
  &.paragraph-left:before {
    content: "\eeb4";
  }
  &.paragraph-center:before {
    content: "\eeb5";
  }
  &.paragraph-right:before {
    content: "\eeb6";
  }
  &.paragraph-justify:before {
    content: "\eeb7";
  }
  &.paragraph-left2:before {
    content: "\eeb8";
  }
  &.paragraph-center2:before {
    content: "\eeb9";
  }
  &.paragraph-right2:before {
    content: "\eeba";
  }
  &.paragraph-justify2:before {
    content: "\eebb";
  }
  &.indent-increase:before {
    content: "\eebc";
  }
  &.indent-decrease:before {
    content: "\eebd";
  }
  &.paragraph-left3:before {
    content: "\eebe";
  }
  &.paragraph-center3:before {
    content: "\eebf";
  }
  &.paragraph-right3:before {
    content: "\eec0";
  }
  &.paragraph-justify3:before {
    content: "\eec1";
  }
  &.indent-increase2:before {
    content: "\eec2";
  }
  &.indent-decrease2:before {
    content: "\eec3";
  }
  &.share:before {
    content: "\eec4";
  }
  &.share2:before {
    content: "\eec5";
  }
  &.new-tab:before {
    content: "\eec6";
  }
  &.new-tab2:before {
    content: "\eec7";
  }
  &.popout:before {
    content: "\eec8";
  }
  &.embed:before {
    content: "\eec9";
  }
  &.embed2:before {
    content: "\eeca";
  }
  &.markup:before {
    content: "\eecb";
  }
  &.regexp:before {
    content: "\eecc";
  }
  &.regexp2:before {
    content: "\eecd";
  }
  &.code:before {
    content: "\eece";
  }
  &.circle-css:before {
    content: "\eecf";
  }
  &.circle-code:before {
    content: "\eed0";
  }
  &.terminal:before {
    content: "\eed1";
  }
  &.unicode:before {
    content: "\eed2";
  }
  &.seven-segment-0:before {
    content: "\eed3";
  }
  &.seven-segment-1:before {
    content: "\eed4";
  }
  &.seven-segment-2:before {
    content: "\eed5";
  }
  &.seven-segment-3:before {
    content: "\eed6";
  }
  &.seven-segment-4:before {
    content: "\eed7";
  }
  &.seven-segment-5:before {
    content: "\eed8";
  }
  &.seven-segment-6:before {
    content: "\eed9";
  }
  &.seven-segment-7:before {
    content: "\eeda";
  }
  &.seven-segment-8:before {
    content: "\eedb";
  }
  &.seven-segment-9:before {
    content: "\eedc";
  }
  &.share3:before {
    content: "\eedd";
  }
  &.share4:before {
    content: "\eede";
  }
  &.mail:before {
    content: "\eedf";
  }
  &.mail2:before {
    content: "\eee0";
  }
  &.mail3:before {
    content: "\eee1";
  }
  &.mail4:before {
    content: "\eee2";
  }
  &.google:before {
    content: "\eee3";
  }
  &.google-plus:before {
    content: "\eee4";
  }
  &.google-plus2:before {
    content: "\eee5";
  }
  &.google-plus3:before {
    content: "\eee6";
  }
  &.google-drive:before {
    content: "\eee7";
  }
  &.facebook:before {
    content: "\eee8";
  }
  &.facebook2:before {
    content: "\eee9";
  }
  &.facebook3:before {
    content: "\eeea";
  }
  &.ello:before {
    content: "\eeeb";
  }
  &.instagram:before {
    content: "\eeec";
  }
  &.twitter:before {
    content: "\eeed";
  }
  &.twitter2:before {
    content: "\eeee";
  }
  &.twitter3:before {
    content: "\eeef";
  }
  &.feed2:before {
    content: "\eef0";
  }
  &.feed3:before {
    content: "\eef1";
  }
  &.feed4:before {
    content: "\eef2";
  }
  &.youtube:before {
    content: "\eef3";
  }
  &.youtube2:before {
    content: "\eef4";
  }
  &.youtube3:before {
    content: "\eef5";
  }
  &.youtube4:before {
    content: "\eef6";
  }
  &.twitch:before {
    content: "\eef7";
  }
  &.vimeo:before {
    content: "\eef8";
  }
  &.vimeo2:before {
    content: "\eef9";
  }
  &.vimeo3:before {
    content: "\eefa";
  }
  &.lanyrd:before {
    content: "\eefb";
  }
  &.flickr:before {
    content: "\eefc";
  }
  &.flickr2:before {
    content: "\eefd";
  }
  &.flickr3:before {
    content: "\eefe";
  }
  &.flickr4:before {
    content: "\eeff";
  }
  &.picassa:before {
    content: "\ef00";
  }
  &.picassa2:before {
    content: "\ef01";
  }
  &.dribbble:before {
    content: "\ef02";
  }
  &.dribbble2:before {
    content: "\ef03";
  }
  &.dribbble3:before {
    content: "\ef04";
  }
  &.forrst:before {
    content: "\ef05";
  }
  &.forrst2:before {
    content: "\ef06";
  }
  &.deviantart:before {
    content: "\ef07";
  }
  &.deviantart2:before {
    content: "\ef08";
  }
  &.steam:before {
    content: "\ef09";
  }
  &.steam2:before {
    content: "\ef0a";
  }
  &.dropbox:before {
    content: "\ef0b";
  }
  &.onedrive:before {
    content: "\ef0c";
  }
  &.github:before {
    content: "\ef0d";
  }
  &.github2:before {
    content: "\ef0e";
  }
  &.github3:before {
    content: "\ef0f";
  }
  &.github4:before {
    content: "\ef10";
  }
  &.github5:before {
    content: "\ef11";
  }
  &.wordpress:before {
    content: "\ef12";
  }
  &.wordpress2:before {
    content: "\ef13";
  }
  &.joomla:before {
    content: "\ef14";
  }
  &.blogger:before {
    content: "\ef15";
  }
  &.blogger2:before {
    content: "\ef16";
  }
  &.tumblr:before {
    content: "\ef17";
  }
  &.tumblr2:before {
    content: "\ef18";
  }
  &.yahoo:before {
    content: "\ef19";
  }
  &.tux:before {
    content: "\ef1a";
  }
  &.apple2:before {
    content: "\ef1b";
  }
  &.finder:before {
    content: "\ef1c";
  }
  &.android:before {
    content: "\ef1d";
  }
  &.windows:before {
    content: "\ef1e";
  }
  &.windows8:before {
    content: "\ef1f";
  }
  &.soundcloud:before {
    content: "\ef20";
  }
  &.soundcloud2:before {
    content: "\ef21";
  }
  &.skype:before {
    content: "\ef22";
  }
  &.reddit:before {
    content: "\ef23";
  }
  &.linkedin:before {
    content: "\ef24";
  }
  &.linkedin2:before {
    content: "\ef25";
  }
  &.lastfm:before {
    content: "\ef26";
  }
  &.lastfm2:before {
    content: "\ef27";
  }
  &.delicious:before {
    content: "\ef28";
  }
  &.stumbleupon:before {
    content: "\ef29";
  }
  &.stumbleupon2:before {
    content: "\ef2a";
  }
  &.stackoverflow:before {
    content: "\ef2b";
  }
  &.pinterest:before {
    content: "\ef2c";
  }
  &.pinterest2:before {
    content: "\ef2d";
  }
  &.xing:before {
    content: "\ef2e";
  }
  &.xing2:before {
    content: "\ef2f";
  }
  &.flattr:before {
    content: "\ef30";
  }
  &.foursquare:before {
    content: "\ef31";
  }
  &.paypal:before {
    content: "\ef32";
  }
  &.paypal2:before {
    content: "\ef33";
  }
  &.paypal3:before {
    content: "\ef34";
  }
  &.yelp:before {
    content: "\ef35";
  }
  &.file-pdf:before {
    content: "\ef36";
  }
  &.file-openoffice:before {
    content: "\ef37";
  }
  &.file-word:before {
    content: "\ef38";
  }
  &.file-excel:before {
    content: "\ef39";
  }
  &.libreoffice:before {
    content: "\ef3a";
  }
  &.html5:before {
    content: "\ef3b";
  }
  &.html52:before {
    content: "\ef3c";
  }
  &.css3:before {
    content: "\ef3d";
  }
  &.git:before {
    content: "\ef3e";
  }
  &.svg:before {
    content: "\ef3f";
  }
  &.codepen:before {
    content: "\ef40";
  }
  &.chrome:before {
    content: "\ef41";
  }
  &.firefox:before {
    content: "\ef42";
  }
  &.IE:before {
    content: "\ef43";
  }
  &.opera:before {
    content: "\ef44";
  }
  &.safari:before {
    content: "\ef45";
  }
  &.IcoMoon:before {
    content: "\ef46";
  }
  &.uni21:before {
    content: "\21";
  }
  &.uni22:before {
    content: "\22";
  }
  &.uni23:before {
    content: "\23";
  }
  &.uni24:before {
    content: "\24";
  }
  &.uni25:before {
    content: "\25";
  }
  &.uni26:before {
    content: "\26";
  }
  &.uni27:before {
    content: "\27";
  }
  &.uni28:before {
    content: "\28";
  }
  &.uni29:before {
    content: "\29";
  }
  &.uni2A:before {
    content: "\2a";
  }
  &.uni2B:before {
    content: "\2b";
  }
  &.uni2C:before {
    content: "\2c";
  }
  &.uni2D:before {
    content: "\2d";
  }
  &.uni2E:before {
    content: "\2e";
  }
  &.uni2F:before {
    content: "\2f";
  }
  &.uni30:before {
    content: "\30";
  }
  &.uni31:before {
    content: "\31";
  }
  &.uni32:before {
    content: "\32";
  }
  &.uni33:before {
    content: "\33";
  }
  &.uni34:before {
    content: "\34";
  }
  &.uni35:before {
    content: "\35";
  }
  &.uni36:before {
    content: "\36";
  }
  &.uni37:before {
    content: "\37";
  }
  &.uni38:before {
    content: "\38";
  }
  &.uni39:before {
    content: "\39";
  }
  &.uni3A:before {
    content: "\3a";
  }
  &.uni3B:before {
    content: "\3b";
  }
  &.uni3C:before {
    content: "\3c";
  }
  &.uni3D:before {
    content: "\3d";
  }
  &.uni3E:before {
    content: "\3e";
  }
  &.uni3F:before {
    content: "\3f";
  }
  &.uni40:before {
    content: "\40";
  }
  &.uni41:before {
    content: "\41";
  }
  &.uni42:before {
    content: "\42";
  }
  &.uni43:before {
    content: "\43";
  }
  &.uni44:before {
    content: "\44";
  }
  &.uni45:before {
    content: "\45";
  }
  &.uni46:before {
    content: "\46";
  }
  &.uni47:before {
    content: "\47";
  }
  &.uni48:before {
    content: "\48";
  }
  &.uni49:before {
    content: "\49";
  }
  &.uni4A:before {
    content: "\4a";
  }
  &.uni4B:before {
    content: "\4b";
  }
  &.uni4C:before {
    content: "\4c";
  }
  &.uni4D:before {
    content: "\4d";
  }
  &.uni4E:before {
    content: "\4e";
  }
  &.uni4F:before {
    content: "\4f";
  }
  &.uni50:before {
    content: "\50";
  }
  &.uni51:before {
    content: "\51";
  }
  &.uni52:before {
    content: "\52";
  }
  &.uni53:before {
    content: "\53";
  }
  &.uni54:before {
    content: "\54";
  }
  &.uni55:before {
    content: "\55";
  }
  &.uni56:before {
    content: "\56";
  }
  &.uni57:before {
    content: "\57";
  }
  &.uni58:before {
    content: "\58";
  }
  &.uni59:before {
    content: "\59";
  }
  &.uni5A:before {
    content: "\5a";
  }
  &.uni5B:before {
    content: "\5b";
  }
  &.uni5C:before {
    content: "\5c";
  }
  &.uni5D:before {
    content: "\5d";
  }
  &.uni5E:before {
    content: "\5e";
  }
  &.uni5F:before {
    content: "\5f";
  }
  &.uni60:before {
    content: "\60";
  }
  &.uni61:before {
    content: "\61";
  }
  &.uni62:before {
    content: "\62";
  }
  &.uni63:before {
    content: "\63";
  }
  &.uni64:before {
    content: "\64";
  }
  &.uni65:before {
    content: "\65";
  }
  &.uni66:before {
    content: "\66";
  }
  &.uni67:before {
    content: "\67";
  }
  &.uni68:before {
    content: "\68";
  }
  &.uni69:before {
    content: "\69";
  }
  &.uni6A:before {
    content: "\6a";
  }
  &.uni6B:before {
    content: "\6b";
  }
  &.uni6C:before {
    content: "\6c";
  }
  &.uni6D:before {
    content: "\6d";
  }
  &.uni6E:before {
    content: "\6e";
  }
  &.uni6F:before {
    content: "\6f";
  }
  &.uni70:before {
    content: "\70";
  }
  &.uni71:before {
    content: "\71";
  }
  &.uni72:before {
    content: "\72";
  }
  &.uni73:before {
    content: "\73";
  }
  &.uni74:before {
    content: "\74";
  }
  &.uni75:before {
    content: "\75";
  }
  &.uni76:before {
    content: "\76";
  }
  &.uni77:before {
    content: "\77";
  }
  &.uni78:before {
    content: "\78";
  }
  &.uni79:before {
    content: "\79";
  }
  &.uni7A:before {
    content: "\7a";
  }
  &.uni7B:before {
    content: "\7b";
  }
  &.uni7C:before {
    content: "\7c";
  }
  &.uni7D:before {
    content: "\7d";
  }
  &.uni7E:before {
    content: "\7e";
  }
  &.uniA9:before {
    content: "\a9";
  }
}
