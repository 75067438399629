























.fd-group {
	width: 100%;

	display: flex;

	& > * {
		margin-left: 5px;

		&:first-child {
			margin-left: 0;
		}
	}

	.fd-button {
		text-overflow: initial;

		i {
			margin: 0;
		}
	}

	.fd-number-input {
		text-align: center;
	}
}
