























.fd-animation-list-input {
	div.input {
		flex: none !important;
		width: 100%;
	}
	font-size: 12px;
	width: 100%;
}
