


































































































































































































































































































.fd-group-module .fd-module:not([data-active]) {
  pointer-events: none;
}
.fd-group-module .fd-decorator {
	pointer-events: none !important;

	& > * {
		display: none !important;
	}
}
