
















































































































































































$yellow: #fec107;

.fd-calc-chooser {

	input, select {
		display: block;
		padding: 6px 12px;
		font-size: 14px;
		color: #555;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		width: 100%;
		outline: none;
		margin: 0;
		box-sizing: border-box;
	}

	.window > .body {
		flex-direction: column;

		.calc-chooser {
			flex: 1;

			.section {

				.head {
					border-bottom: 1px solid $yellow;
					margin-bottom: 10px;
				}

				.body {
					input, select {
						font-family: "Courier New", Consolas, monospace;
					}

					button {
						width: initial;
						background: $yellow;
						color: white;
						font-size: 0.9em;
						text-transform: uppercase;
						text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					}
				}

				&.calc {
					.body {
						display: flex;

						input {
							flex: 1;
							margin-right: 5px;
						}
					}
				}

				&.settings {
					.head {
						margin-top: 25px;
					}

					.body {
						display: flex;
						justify-content: space-between;

						& > div {
							width: 31%;
						}

						label {
							display: block;
							font-size: smaller;
						}

						input {
							padding: 7px 12px;
						}

						select, input {
							height: 32px;
						}
					}
				}
			}
		}
	}
}
