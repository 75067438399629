











































































































































































































































































.fd-modules {
	&.selecting *:not(.selector) {
		pointer-events: none;
	}

	.selector {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(135, 163, 191, 0.3);
		border: 2px dashed #39f;
		z-index: 999999999;
		&.nearest {
			border: 1px dashed #f00 !important;
		}
		&.middle {
			border: 1px dashed #fafb3d !important;
		}
	}

}
