






















































































































































































































































































































































































































































































.fd-color-picker-view {
	position: absolute;
	top: 0;
	width: 482px;
	background: black;
	border: 1px solid #fff;

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		"mouse keyboard"
		"mouse footer";

	.mousePicker {
		grid-area: mouse;
		padding: 10px;

		.comparer {
			width: 100px;
			height: 20px;
			border: 1px solid #fff;
			border-radius: 5px;
			overflow: hidden;

			background: url('../../img/colorpicker_colorbg.png');

			display: flex;
			div {
				flex: 1;
				background: currentColor;
				transform: skewX(-25deg) scaleX(1.1);
			}

			.old {
				transform-origin: right center;
			}

			.new {
				transform-origin: left center;
				border-left: 1px solid #fff;
			}
		}

		.hSelector,
		.svSelector,
		.aSelector {
			position: absolute;
			border: 1px solid white;
			border-radius: 4px;
			filter: drop-shadow(0 0 2px black);

			cursor: move;
			cursor: grab;

			&.active {
				cursor: grabbing;
			}
		}

		.mouseHSV {
			width: 100%;
			padding-bottom: 100%;
			margin-top: 10px;
			position: relative;

			&.active {
				cursor: grabbing;
			}

			canvas {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.hSelector {
				top: 0;
				left: calc(50% - 3px);
				width: 4px;
				height: 24px;

				transform-origin: 3px 110.5px;
			}

			.svSelector {
				top: -3.5px;
				left: -3.5px;
				width: 6px;
				height: 6px;
			}
		}

		.mouseAlpha {
			position: relative;
			margin-top: 10px;
			height: 20px;
			border: 1px solid #fff;
			border-radius: 5px;

			background: url('../../img/colorpicker_colorbg.png');

			.background {
				width: 100%;
				height: 100%;
				border-radius: 5px;

				background: linear-gradient(to right, rgba(0,0,0,0) 0%, currentColor 100%);
			}

			&.active {
				cursor: grabbing;
			}

			.aSelector {
				top: -2px;
				left: -3px;
				width: 4px;
				height: 22px;
			}
		}
	}

	.keyboardPicker {
		grid-area: keyboard;

		padding: 10px;

		.config-item {
			display: flex;
			align-items: center;

			label {
				font-family: OpenSans, sans-serif;
				font-size: 11px;
				color: #fff;
				flex: 0;
				margin-right: 10px;
			}

			.input {
				flex: 1;
			}

			* {
				box-sizing: border-box;
			}
		}

		.config-item.hex,
		.config-item.alpha {
			width: 50%;
		}

		.config-item.alpha {
			margin-left: 50%;
		}

		.row {
			margin: 7px -10px;

			display: flex;

			.col {
				flex: 1;
				margin: 0 10px;

				label {
					flex-basis: 12px;
				}

				.config-item {
					margin: 3px 0;
				}
			}
		}

		.color-grid {
			margin-top: 25px;

			display: grid;
			grid-template-columns: repeat(10, 1fr);
			grid-gap: 4px;

			.color {
				border: 1px solid #fff;
				background: url('../../img/colorpicker_colorbg.png');
				cursor: pointer;

				div {
					padding-bottom: 100%;
				}
			}
		}
	}

	.footer {
		grid-area: footer;

		display: flex;
		justify-content: flex-end;
		padding: 10px 0;

		button {
			width: auto;
			margin-right: 10px;
		}
	}
}
