











.fd-config-item.Raw {
	.label {
		display: none;
	}
	.input {
		flex: 0 0 100% !important;
	}
}

.fd-raw {
	width: 100%;
}
