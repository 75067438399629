


































































































































.fd-sub-nav-view {
	&, & * {
		box-sizing: border-box;
	}

	color: #fff;
	background: rgba(0, 0, 0, .9);
	border-left: 1px solid #333;
	transition: transform 0.3s;

	.control-bar,
	.scroller {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.control-bar {
		z-index: 30;

		display: flex;
		justify-content: flex-end;

		button {
			color: #fff;
			cursor: pointer;
			padding: 5px;

			background: none;
			border: none;
			outline: none;

			&.smaller {
				font-size: 10px;
				padding: 4px 5px 6px;
			}
			&.bigger {
				font-size: 16px;
			}
		}
	}

	.scroller {
		z-index: 10;
		height: 100%;
		padding: 7px;

		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;

		display: flex;
		flex-direction: column;
	}

	.resizer {
		position: absolute;
		top: 0;
		right: -2px;
		height: 100%;
		z-index: 20;

		width: 4px;
		cursor: ew-resize;
	}
}
