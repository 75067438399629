

















.fd-config-item.Button {
	.label {
		display: none;
	}
	.input {
		flex: 0 0 100% !important;
	}
}

.fd-button {
	width: 100%;

	font-size: 11px;
	line-height: 15px;
	padding: 6px 12px;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	outline: none;
	border-radius: 3px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	i {
		margin-right: 5px;
		display: inline-block;
	}

	&.selected {
		background: hsla(0,0%,100%,.2);
		color: #fff;
	}

	&.danger {
		background: rgb(235, 0, 0);
		color: white;
	}

	&.italic { font-style: italic; }
	&.bold { font-weight: bold; }
	&.underline { text-decoration: underline; }
}
