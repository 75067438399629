






















































































































































































































































.fd-animation-list-item-view {
	display: flex;
	margin: 0;
	padding: 5px 0;
	.row {
		width: 23px;
		text-align: center;
	}
	.image {
		flex: none;
		width: 20px;
		border-right: 1px solid #fff;
		i {
		cursor: pointer;
		}
	}
	.slider {
		position: relative;
		flex: 1;
		.move {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 100%;
			background: #fff;
			cursor: ew-resize;
		}
		.move::after {
			content: '';
			position: absolute;
			top: 0;
			left: auto;
			right: -1px;
			height: 100%;
			width: 3px;
			cursor: col-resize;
		}
		.move::before {
			content: '';
			position: absolute;
			top: 0;
			left: -1px;
			height: 100%;
			width: 3px;
			cursor: col-resize;
		}
	}
}
