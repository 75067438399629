































































$fd_background: '../img/pattern.png';

.fd-instance,
.fd-renderer-view {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	overflow: hidden;
}

.fd-instance {
	background: url($fd_background) black;
}
