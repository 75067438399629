



















































































































































































$main-nav-width: 46px;
$toolbar-width: 30px;
$infobar-height: 22px;

.fd-editor-view {
	display: grid;
	grid-template-columns: $main-nav-width 0px 1fr $toolbar-width;
	grid-template-rows: 1fr $infobar-height;
	grid-template-areas:
		"main-nav sub-nav renderer toolbar"
		"infobar  infobar infobar  infobar";

	&.show-mode {
		grid-template: "renderer" 1fr / 1fr;
	}
}

.fd-preloader-view {
	z-index: 30;
}

.fd-main-nav-view {
	grid-area: main-nav;
	z-index: 20;
}

.fd-sub-nav-view {
	grid-area: sub-nav;
	z-index: 10;
}

.fd-renderer-view {
	grid-area: renderer;
}

.fd-toolbar-view {
	grid-area: toolbar;
}

.fd-infobar-view {
	grid-area: infobar;
}

.fd-editor-extra-view {
	z-index: 40;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: rgba(255,255,255,0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(187, 187, 187, 0.5);
  &:hover {
    background-color: rgb(170, 170, 170);
  }
}
