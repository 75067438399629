

















































































.fd-variable-chooser {
	.body {
		flex-direction: column;

		.search {
			display: flex;
			margin-bottom: 5px;

			input {
				flex: 1;

				font-size: 14px;
				padding: 6px 7px;
				outline: none;
				border: 1px solid #bbb;
				border-radius: 4px;
			}
		}

		.var-chooser {
			flex: 1;
			max-height: 400px;
			overflow-y: auto;

			.variable {
				input {
					display: none;
				}

				input:checked + label {
					color: #fff;
					background: #888;
				}

				label {
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					border-radius: 3px;
					padding: 5px;

					&:hover {
						background: #eee;
					}

					span.value {
						font-family: Consolas, "Currier New", monospace;
						font-size: smaller;
					}
				}
			}
		}
	}
}
