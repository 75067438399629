

































































.fd-file-chooser {
	.body {
		flex-direction: column;
	}

	.preview {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 5px;
		padding: 5px;
		margin: -5px;

		max-height: 280px;
		overflow-y: auto;

		.fd-file-view {
			cursor: inherit;
		}
	}

	button.attention {
		background: red;
	}
}
