



















































.fd-delete-template-modal-view {
	.body {
		flex-direction: column;
	}

	.center {
		text-align: center;
	}

	.strong {
		font-size: larger;
		font-weight: 600;
	}

	button.attention {
		background: red;
	}
}
