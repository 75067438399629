

















































.fd-pane {
	position: absolute;
	top: 50%;
	left: 50%;

	overflow: hidden;
}

.grid {
	background-image:
		linear-gradient(0deg, rgba(255, 255, 255, .2) 0px, rgba(255, 255, 255, .2) 1px, transparent 2px, transparent 100%),
		linear-gradient(90deg, rgba(255, 255, 255, .2) 0px, rgba(255, 255, 255, .2) 1px, transparent 2px, transparent 100%);

	background-position: center;
	background-size: 3.125% 5.555555555%;
}

.dashboard {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 15;
}

.fps-info {
	position: absolute;
	top: 0;
	right: 50px;
	z-index: 55;
	font-size: 50px;
	text-align: right;
	color: #888;
	text-shadow: 0 1px 2px #000, 0 -1px 2px #fff;
	pointer-events: none;
}
